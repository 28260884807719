<template>

    <div>
        <nav-bar></nav-bar>
        <main class="main">

            <div class="wrapper" v-if="response && openSection === ''">
                <h1 class="red-heading">VestiGO Order {{ response.order.orderNumber }} Artwork PDF Proof Preview</h1>

                <div class="quote-wrapper">
                    <div class="main-section">
                        <p>Hi {{ response.order.user.name }} {{ response.order.user.lastName }} ,</p>
                        <p><strong> {{ response.item.product?.productName ?? response.item.quote_id?.quotesProduct }}
                            </strong></p>
                        <p v-if="response.order.supplierComment"><strong>Supplier Response :</strong> {{ response.order.supplierComment }}</p>
                        <p v-else>Your PDF Proof Preview File is available to download for your review and approval.</p>
                        <p> <a href="javascript:void(0)" class="button"
                                @click="downloadFile($store.state.URL + '/uploads/artworks/' + response.item.artwork, response.item.artwork)">Download
                                PDF Proof Preview</a> </p>
                        <div class="radio-options">
                            <label>
                                <input type="radio" name="approval" value="approve" v-model="modelApproval">
                                Yes, this looks great. Please use these files for production.
                            </label>
                            <label>
                                <input type="radio" name="approval" value="review" v-model="modelApproval">
                                There may be an issue with the proof artwork preview file’, I would like to send a note
                                to your artwork specialists.
                            </label>
                            <label>
                                <input type="radio" name="approval" value="resend" v-model="modelApproval">
                                There is a mistake in my files. I would like to upload a new file.
                            </label>
                        </div>
                        <p> <a href="javascript:void(0)" class="button" @click="countinueApproval">CONTINUE</a> </p>
                        <p class="footers">Thank You,<br>VestiGO Artwork Team.</p>
                    </div>


                </div>

            </div>
            <div v-if="resentTab">
                <div class="wrapper">
                    <h1 class="red-heading">VestiGO Order {{ response.order.orderNumber }} Artwork PDF Proof Preview
                    </h1>

                    <div class="quote-wrapper">
                        <div class="main-section">
                            <p>Hi {{ response.order.user.name }} {{ response.order.user.lastName }} ,</p>
                            <p><strong>ACTION REQUIRED:</strong> Please let us know your issue within the RESPOND box
                                and our team
                                will act fast to carefully check the issues and respond within 24 hours.</p>
                            <div>
                                <textarea id="response" name="response" rows="7" style="width: 100%;"
                                    placeholder="Enter your response here..." required v-model="notes"></textarea>
                            </div> <br>
                            <button type="button" @click="submitNotes"
                                style="background-color: #c4a86a; color: white; padding: 8px 16px; border: none; cursor: pointer;">
                                Submit </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="message-section" v-if="openSection === 'approve'">
                <div class="message-box">
                    <p>Thank You</p>
                    <p>Your design file is in good hands and has been moved to our print production.</p>
                    <p>Please view your order status within your dashboard for updates OR from email notifications from
                        our production team.</p>
                    <a href="/user-dashboard/jobs" class="button" @click="openSection = ''">Back</a>
                </div>
            </div>
            <div class="message-section" v-if="openSection === 'review'">
                <div class="message-box">
                    <p>Thank You</p>
                    <p>Our Artwork Team will review your response to the Artwork Proof Preview and contact you shortly.
                        Our team will fix any issues very quickly that may have occurred with the file. </p>
                    <a href="/user-dashboard/jobs" class="button" @click="openSection = ''">Back</a>
                </div>
            </div>
            <div class="message-section" v-if="openSection === 'resend'">
                <div class="message-box">
                    <p>Thank You</p>
                    <p>Please Upload your Updated Artwork File and our Artwork Team will shortly send an updated Artwork
                        PDF Proof Preview file.</p>
                    <div class="" style="padding:10px;border:2px solid lightgray;">
                        <div style="border:1px dotted lightgray;padding:10px;position:relative;">
                            <input type="file" name="" id="" class="v-input" @change="handle">

                            <img src="/images/download.png" alt="" class="" srcset=""
                                style="width:110px;display: block; margin-left:auto; margin-right:auto;">
                            <p>Click here to choose the files or drag them here</p>
                        </div>
                        <div>
                        </div>
                        <div v-if="$store.state.uploadProgress">
                            <b>Uploading:</b> <span> {{ $store.state.uploadProgress }}% </span>
                        </div>
                        <div v-if="allFiles">
                            {{ allFiles.name }}
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-upload btn-success" v-if="allFiles !== null"
                            @click="startUploadArt(cartItem)" style="width: 291px;margin: 5px;">
                            Send Updated Artwork File
                        </button>
                    </div>
                    <br>
                    <br>


                    <p style="font-size: 12px;">Please note, delivery time of your order commences from approval of
                        print ready PDF artwork.</p>
                    <a href="/user-dashboard/jobs" class="button" @click="openSection = ''">Back</a>
                </div>
            </div>
        </main><!--Main index : End-->
        <div style="position:relative;">
            <footer-section></footer-section>
        </div>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterSection from "@/components/FooterSection.vue";
import { mapState } from "vuex";

export default {
    components: { NavBar, FooterSection },

    computed: {
        ...mapState(["user", "cartItem", "orderItemUpdates", "order"]),
    },

    async created() {
        this.fetchCartItem();
    },

    methods: {
        handle(event) {
            this.allFiles = []
            this.allFiles = event.target.files[0]
            // console.log(event, this.allFiles)
            const reader = new FileReader();
            reader.readAsDataURL(this.allFiles);
            reader.onload = () => {
                // this.images.push(reader.result)
            };


        },
        async startUploadArt(item) {
            if (this.allFiles !== null) {
                let name = Date.now() + this.allFiles.name
                const formData = new FormData();
                formData.append('encType', 'multipart/form-data');

                formData.append('artworks', this.allFiles, name)
                // console.log(formData)
                let dec = await this.$store.dispatch("uploadFilesArtworks", formData)

                let supplierEmail = this.order.items[0].product ? this.order.items[0].product.product.supplierEmail : this.order.items[0].quote_id.supplierEmail;
                let actionType = "resend";

                await this.$store.dispatch('artworkAction', { item, name, order: this.order, supplierEmail, actionType })
                alert("Uploaded successfully!")
                this.order.artworkComment = ''
                this.order.status = 'Artwork Proofing'
                this.order.items[0].artwork = name
                this.allFiles = null;
                setTimeout(() => {
                    this.$store.state.uploadProgress = 0
                }, 1000);

            }

        },
        async fetchCartItem() {
            const { item, order } = this.$route.params;

            if (!item || !order) {
                console.warn("Missing route parameters: item or order");
                return;
            }

            try {
                this.isLoading = true;
                this.response = await this.$store.dispatch("getCartItem", { item, order });
            } catch (error) {
                console.error("Error fetching cart item:", error);
            } finally {
                this.isLoading = false;
            }
        },

        downloadFile(fileUrl, fileName) {
            if (fileName) {
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = fileName;
                        link.click();
                        URL.revokeObjectURL(url);
                    })
                    .catch(error => console.error('Error:', error));
            }
        },
        countinueApproval() {
            if (!this.modelApproval) {
                alert("Please select an approval option!");
                return;
            }
            if (this.modelApproval == 'review') {
                this.resentTab = true;
                this.openSection = 'true'
                return;
            }
            let items = {}
            items.actionType = this.modelApproval
            items.cartItemId = this.$route.params.item
            items.orderNumber = this.$route.params.order
            const result = this.$store.dispatch("artworkAction", items);
            // console.log("Update successful:", result);
            // if(result.status)
            this.openSection = this.modelApproval;
        },
        submitNotes(){
            // alert(this.notes)
            let supplierEmail = this.order.items[0].product ? this.order.items[0].product.product.supplierEmail : this.order.items[0].quote_id.supplierEmail;
            let items = {}
            items.notes = this.notes
            items.cartItemId = this.$route.params.item
            items.orderNumber = this.$route.params.order
            items.supplierEmail = supplierEmail
            items.notesBy = 'user'

            const result = this.$store.dispatch("orderArtworkNotesSave", items);
            this.openSection = 'review'
            this.resentTab = false;
            this.modelApproval = ''
        },
    },

    data() {
        return {
            isLoading: false,
            response: '',
            allFiles: [],
            modelApproval: '',
            openSection: '',
            resentTab: false,
            notes: ''
        };
    },
};
</script>

<style>
.wrapper {
    width: 100%;
}

.wrapper .red-heading {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    color: #c32025;
    display: flex;
    margin: 50px 0 25px;
    justify-content: center;
}

.main-section {
    width: 60%;
    margin: 0 auto;
    border: 3px solid #322f2f;
    padding: 20px;
    margin-bottom: 50px;
}

.button {
    display: inline-block;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
}

.button:hover {
    background-color: #0056b3;
    color: white;
}

.radio-options {
    margin: 15px 0;
}

.radio-options label {
    display: block;
    margin: 5px 0;
}

.footers {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
}

.message-section {
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 50px;
}

.message-box {
    border: 2px solid #494545;
    padding: 20px;
    text-align: center;
    background-color: white;
    color: #52504c;
    font-size: 16px;
}

.v-input {
    position: absolute;
    z-index: 9999;
    height: 100%;
    border: 2px solid red;
    width: 100%;
    opacity: 0;
}
</style>