<template>

	<div>
		<nav-bar></nav-bar>
		<main id="main category">

			<section class="category-w parten-bg card-position">
				<div class="container">
					<div class="row">
						<aside id="sidebar_cate" class="col-sm-3 hidden-xs" v-if="vw > 480">
							<h3 class="sidebar-title">Category</h3>
							<ul id="cate_list" class="cate_list">
								<li class="level0 parent" v-for="cat in categories" :key="cat.id">
									<a href="javascript:void(0)" title="Business Cards">
										<span>{{ cat.name }}</span>
										<i class="fa fa-minus" @click="toggleDisplay(cat._id)"></i>
										<i class="fa fa-plus" @click="toggleDisplay(cat._id, 'block')"></i>
									</a>
									<ul class="level0" :id='cat._id'>
										<li class="level1 nav-1-1 first item" @click="loadProducts(subcat.slug, subcat.name)"
											v-for="subcat in subCategories" :key="subcat._id"
											v-if="subcat.category._id == cat._id">
											<a href="javascript:void(0)" :title="subcat.name">
												{{ subcat.name }}
												<!-- <span class="count-item">(22)</span> -->
											</a>
										</li>

									</ul>
								</li>

								<!-- <li class="level0" style="cursor: pointer;" v-for="category in categories" :key="category._id" @click="loadProducts(category.slug)">
								<a  :title="category.name">
									{{ category.name }}
								</a>
							</li> -->
								<!-- <li class="level0">
								<a href="#" title="Carbonless Form">
									Carbonless Form
									<span class="count-item">(10)</span>
								</a>
							</li>
							<li class="level0">
								<a href="#" title="Catalogs">
									Catalogs
									<span class="count-item">(22)</span>
								</a>
							</li>
							<li class="level0" title="Counter Cards">
								<a href="#">
									Counter Cards
									<span class="count-item">(45)</span>
								</a>
							</li>
							<li class="level0" title="Door Hangers">
								<a href="#">
									Door Hangers
									<span class="count-item">(30)</span>
								</a>
							</li>
							<li class="level0" title="Envelopes">
								<a href="#">
									Envelopes
									<span class="count-item">(25)</span>
								</a>
							</li>
							<li class="level0" title="Flyers">
								<a href="#">
									Flyers
									<span class="count-item">(6)</span>
								</a>
							</li> -->
							</ul>
							<!-- <div class="category-left-banner">
								<a href="#" title="category left banner">
									<img src="/images/banner/category/left-banner.jpg" alt="Left banner">
								</a>
							</div> -->
							<div style="margin-top: 25%;">
								<div class="card-img">
									<img src="/images/testimonials/quotes_slider.png" alt="">
								</div>
								<div class="card-text">
									<p>
										Product or Service not listed here, no problem <a href="/quotes-form">QUOTES</a> team here,
										explain
										your requirement and our team will respond within 24-48 hrs with a competitive price
									</p>
								</div>
							</div>
						</aside>
						<!--Category product grid : Begin -->
						<section class="category grid col-sm-9 col-xs-12">
							<div class="row">
								<div class="col-xs-12 category-image visible-md visible-lg">
									<a href="javascript:void(0)" title="category image" style="text-decoration: none;">
										<div v-if="productBannerData?.position" class="postCardWrap"
											:class="productBannerData.position"
											:style="{ backgroundColor: productBannerData.bgColor, fontFamily: productBannerData.fontFamily }">
											<div class="postCont">
												<div class="cardTitle"
													:style="{ color: productBannerData.headingColor, fontSize: productBannerData.headingSize, fontWeight: productBannerData.headingBold ? 'bold' : 'normal' }">
													{{ productBannerData.heading }}</div>
												<p
													:style="{ color: productBannerData.subheadingColor, fontSize: productBannerData.subheadingSize, fontWeight: productBannerData.subheadingBold ? 'bold' : 'normal' }">
													{{ productBannerData.subheading }}</p>
											</div>
											<div class="postImg">
												<img :src="productBannerData.previewImage" alt="Slider Image" />
											</div>
										</div>
										<!-- <img src="/images/banner/category/top-business-card-large.jpg"
											alt="Business card"> -->
									</a>
								</div>
								<!-- <div class="col-xs-12 category-image visible-sm">
									<a href="#" title="category image">
										<img src="/images/banner/category/top-business-card-medium.jpg"
											alt="Business card">
									</a>
								</div>
								<div class="col-xs-12 category-image visible-xs">
									<a href="#" title="category image">
										<img src="/images/banner/category/top-business-card-small.jpg"
											alt="Business card">
									</a>
								</div> -->
							</div>
							<div class="top-toolbar row">
								<div class="col-md-3 col-sm-4 col-xs-4 type-filter" style="display:none;">
									<span class="cate-filter-title hidden-xs">Product Type:</span>
									<div class="cate-filter-content">
										<span class="current-filter hidden-xs">Business<i
												class="fa fa-angle-down"></i></span>
										<span class="current-filter visible-xs">Type: <i
												class="fa fa-angle-down"></i></span>
										<ul class="product-type-filter">
											<li>
												<span>Flyers & Leaflets</span>
											</li>
											<li class="selected">
												<span>Business Card</span>
											</li>
											<li>
												<span>Poster Prints</span>
											</li>
											<li>
												<span>Card & Invites</span>
											</li>
											<li>
												<span>Banner & POS</span>
											</li>
											<li>
												<span>Stationery</span>
											</li>
										</ul>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-4 industry-filter" style="display:none;">
									<span class="cate-filter-title hidden-xs">Type/Industry:</span>
									<div class="cate-filter-content">
										<span class="current-filter hidden-xs">Select a Style<i
												class="fa fa-angle-down"></i></span>
										<span class="current-filter visible-xs">Industry: <i
												class="fa fa-angle-down"></i></span>
										<ul class="product-type-filter">
											<li>
												<span>Animals</span>
											</li>
											<li>
												<span>Art</span>
											</li>
											<li>
												<span>Automotive</span>
											</li>
											<li>
												<span>Beauty</span>
											</li>
											<li>
												<span>Beverage</span>
											</li>
											<li>
												<span>Child Care</span>
											</li>
										</ul>
									</div>
								</div>
								<div class="col-12 view-mode">
									<a class="view-grid" :class="{ 'active': view == 'Grid' }" @click="view = 'Grid'">
										<i class="fa fa-th"></i>
									</a>
									<a class="view-list" :class="{ 'active': view == 'List' }" @click="view = 'List'">
										<i class="fa fa-th-list"></i>
									</a>
								</div>
							</div>
							<div class="row products-grid category-product"
								v-if="view == 'Grid' && productsCategory.length > 0">
								<ul>
									<li class="pro-item col-md-4 col-sm-6 col-xs-12" v-for="pro in productsCategory"
										:key="pro._id">
										<div class="product-image-action">
											<img :src="$store.state.URL + '/uploads/product/' + pro.image"
												:alt="pro.productName">
											<div class="action">
												<button type="button" data-toggle="tooltip" data-placement="top"
													class="add-to-cart gbtn">
													<i class="fa fa-shopping-cart"></i>
												</button>
												<a href="#" data-toggle="tooltip" data-placement="top" title="Wishlist"
													class="add-to-wishlist">
													<i class="fa fa-heart"></i>
												</a>
												<a href="#" data-toggle="tooltip" data-placement="top" title="compare"
													class="add-to-compare">
													<i class="fa fa-refresh"></i>
												</a>
												<a href="#" data-toggle="tooltip" data-placement="top" title="Quickview"
													class="quick-view">
													<i class="fa fa-eye"></i>
												</a>
											</div>
										</div>
										<div class="product-info">
											<a :href="'/custom-product/' + pro._id" title="product"
												class="product-name">{{ pro.productName }}</a>
											{{ pro.productMktDesc }}
											<!-- <div class="price-box">
											<span class="normal-price">$ {{ pro.quantityGroups[0].sellingProductPrice }}</span>
										</div> -->
											<div class="rating-box">
												<i class="fa fa-star"></i>
												<i class="fa fa-star"></i>
												<i class="fa fa-star-half-o"></i>
												<i class="fa fa-star-o"></i>
												<i class="fa fa-star-o"></i>
											</div>

										</div>
									</li>

								</ul>
							</div>
							<div v-if="productsCategory.length == 0" style="text-align: -webkit-center;">
								<div style="border: 1px solid #c4a86a; padding: 15px; width: 70%; font-size: x-large;">
									<p><strong>ACTION REQUIRED :</strong> No products found in the <strong> '{{ formatCategoryName($route.params.category) }}' </strong> category. Please check back later or explore our other categories!</p> <br>
								</div>
							</div>
							<div class="products-list category-product"
								v-if="view == 'List' && productsCategory.length > 0">
								<ul>
									<li class="pro-item row" v-for="pro in productsCategory" :key="pro._id">
										<div class="product-image-action col-sm-4 col-xs-12">
											<a href="#" :title="pro.productName">
												<img :src="$store.state.URL + '/uploads/product/' + pro.image"
													:alt="pro.productName">
											</a>
										</div>
										<div class="product-info col-sm-8 col-xs-12">
											<div class="pro-name-rate clearfix">
												<h3 class="product-name">
													<a :href="'/custom-product/' + pro._id" :title="pro.productName">{{
														pro.productName }}</a>
												</h3>
												<p>
													{{ pro.productMktDesc }}
												</p>
												<div class="rating-box">
													<i class="fa fa-star"></i>
													<i class="fa fa-star"></i>
													<i class="fa fa-star-half-o"></i>
													<i class="fa fa-star-o"></i>
													<i class="fa fa-star-o"></i>
												</div>
											</div>
											<!-- <div class="price-box">
											<span class="normal-price">$ {{ pro.quantityGroups[0].sellingProductPrice }}</span>
										</div> -->
											<div class="short-description">
												<p class="visible-md visible-lg">
													{{ pro.productMktDesc }}
												</p>
												<p class="visible-sm">
													{{ pro.productMktDesc }}
												</p>
											</div>
											<div class="action">
												<button type="button" class="add-to-cart gbtn" title="Add to cart">
													<i class="fa fa-shopping-cart"></i>
													<span>Add to cart</span>
												</button>
												<a href="#" title="Add to Wishlist" class="add-to-wishlist">
													<i class="fa fa-heart"></i>
												</a>
												<a class="add-to-compare" title="add-to-compare">
													<i class="fa fa-refresh"></i>
												</a>
												<a class="quick-view" title="">
													<i class="fa fa-eye"></i>
												</a>
											</div>
										</div>
									</li>

								</ul>
							</div>

						</section><!-- Category product grid : End -->
					</div>

					<!-- <div class="row">
						<div class="col-md-12 visible-md visible-lg cate-bottom-banner">
							<a href="#" title="category bottom banner">
								<img src="/images/banner/category/category-bottom-banner-large.jpg" alt="Business card">
							</a>
						</div>
						<div class="col-sm-12 visible-sm cate-bottom-banner">
							<a href="#" title="category bottom banner">
								<img src="/images/banner/category/category-bottom-banner-medium.jpg"
									alt="Business card">
							</a>
						</div>
						<div class="col-xs-12 visible-xs cate-bottom-banner">
							<a href="#" title="category bottom banner">
								<img src="/images/banner/category/category-bottom-banner-small.jpg" alt="Business card">
							</a>
						</div>
					</div> -->
				</div>
			</section>

		</main><!-- Main Category: End -->
		<!--Footer : Begin-->
		<div style="position:relative;">
			<footer-section></footer-section>
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import { mapState } from 'vuex'
export default {
	computed: {
		...mapState(['categories', 'subCategories', 'productBannerData']),
		vw() {
			const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			console.log(vw)
			return vw
		}
	},
	components: { NavBar, FooterSection },
	methods: {
		formatCategoryName(name) {
			return name.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
		},
		goTo(slug) {
			location.replace(slug)
		},
		toggleDisplay(id,) {
			let el = document.getElementById(id);
			console.log(id, el.style.display)
			if (el.style.display == 'block') {
				el.style.display = 'none'
			} else {
				el.style.display = 'block'
			}
		},
		async loadProducts(slug, catName) {
			// console.log("loading products", slug)
			this.catName = catName;			
			this.productsCategory = await this.$store.dispatch("getProductsByCategory", { category: slug });
			this.$router.push({ name: "Category", params: { category: slug } })
		}
	},
	data() {
		return {
			productsCategory: null,
			view: "Grid",
			catName: ''
		}
	},
	async mounted() {
		await this.$store.dispatch("getCategories")
		await this.$store.dispatch("getProductBannerData")
		this.productsCategory = await this.$store.dispatch("getProductsByCategory", { category: this.$route.params.category });

	}
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.card-position {
	position: relative;
}

.product-card-wrapper {
	max-width: 208px;
	width: 100%;
	/* border: 1px solid #c32025; */
	position: absolute;
	top: 35%;
	right: 25px;
}

.horizontal-card {
	max-width: 370px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	gap: 25px;
}

.product-card-wrapper .card-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.card-text {
	width: 100%;
	text-align: left;
	padding: 10px 15px 0 15px;
}

.card-text p {
	font-size: 17px;
	font-weight: 400;
	font-family: "Roboto Condensed", sans-serif;
	color: #242656;
	margin-bottom: 10px;
}

.card-text h2 {
	font-size: 24px;
	text-transform: uppercase;
	margin-bottom: 10px;
	color: #444;
	font-weight: 700;
	font-family: "Roboto Condensed", sans-serif;
}

.card-text p a {
	color: #c32025;
}

.card-btn {
	border: none;
	background-color: transparent;
	font-size: 16px;
	font-weight: 400;
	font-family: "Roboto Condensed", sans-serif;
	color: crimson;
	position: relative;
}

.btn-arrow::after {
	position: absolute;
	content: '';
	/* background-image: url(arrow.svg); */
	background-repeat: no-repeat;
	background-size: cover;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	right: -25px;

}

.container {
	width: 1170px;
	margin: auto !important;
}

.sidebar-title {
	padding-left: 12px;
}

.category-w.parten-bg {
	margin-top: 20px;
}

.c-navbar__sticky .c-navbar__item.second-header a::before {
	opacity: 0;
}

/* product banner */
.postCardWrap {
	display: flex;
	padding: 17px 18px 17px 18px;
	position: relative;
	justify-content: space-between;
	gap: 15px;
	height: 100%;
}

.postCardWrap.position-right {
	flex-direction: row-reverse;
}

.postCardWrap .postCont {
	max-width: 60%;
	width: 100%;
}

.postCardWrap.position-right .postCont {
	max-width: 60%;
	width: 100%;
	margin-left: 17px;
}

.postCardWrap.position-right .postImg {
	max-width: 40%;
	width: 100%;
}

.postCardWrap.position-right .postImg img {
	left: 0;
}

.postCardWrap .postImg {
	max-width: 40%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.postCardWrap .postImg img {
	width: 100%;
	aspect-ratio: 1/0.6;
	object-fit: cover;
	height: unset;
	position: unset;
}
</style>