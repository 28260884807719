<template>
	<div v-if="mainProduct">
		<nav-bar></nav-bar>

		<main class="main" id="product-detail">
			<!--Breadcrumb : Begin-->

			<section class="product-info-w">
				<div class="container">


					<div class="row">
						<div class="tab-content clearfix">
							<div role="tabpanel" class="tab-pane active" id="features">
								<div class="product-image">
									<div v-if="loaded">
										<div class="col-sm-12 col-xs-12" v-if="product">
											<img :src="$store.state.URL + '/uploads/product/' + product.image"
												alt="ideal 1">
										</div>
										<div class="col-sm-12 col-xs-12" v-else>
											<img :src="$store.state.URL + '/uploads/product/' + mainProduct.image"
												alt="ideal 1">
										</div>
										<div class="col-sm-12">


											<a @click="goTo('HowToOrderOnline')"> <b>How to Order Online ? Click here to
													view</b> </a>
										</div>
									</div>
								</div>
								<div class="product-shortdescript v-middle" v-if="mainProduct">
									<div class="col-sm-12 col-xs-12">
										<!-- here -->
										<div class="" v-if="needArtwork">
											<h3 style="margin-bottom:15px;">{{ mainProduct.productName }}</h3>
											<div class="col-12" style="margin-bottom:15px;">
												<div class="rating-box">
													<i class="fa fa-star"></i>
													<i class="fa fa-star"></i>
													<i class="fa fa-star-half-o"></i>
													<i class="fa fa-star-o"></i>
													<i class="fa fa-star-o"></i>
													<span>(23 Customer Reviews)</span>
												</div>
											</div>
											<!-- <p v-if="product !== null">
											{{ product.productDesc }}
										</p> -->
											<p>
												{{ mainProduct.productDesc }}
											</p>


											<div v-if="reorderProduct.items[0].opData.printed">
												<label for="" class="d-block">Print</label>
												<select name="" id="" class="form-control" :disabled="true">
													<option :value="reorderProduct.items[0].opData.printed">{{
														reorderProduct.items[0].opData.printed }}</option>
												</select>

											</div>

											<div v-if="reorderProduct.items[0].product.orient">
												<label for="" class="d-block">Orientation</label>

												<select name="" id="" class="form-control" :disabled="true">
													<option :value="reorderProduct.items[0].product.orient"> {{
														reorderProduct.items[0].product.orient }}</option>
												</select>

											</div>
											<div v-if="reorderProduct.items[0].product.productPaperSizeFormat">
												<label for="">Size</label>
												<select name="" id="" class="form-control" style="margin-bottom:15px;"
													:disabled="true">
													<option
														:value="reorderProduct.items[0].product.productPaperSizeFormat">
														{{ reorderProduct.items[0].product.productPaperSizeFormat }}
													</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Size Format:</b> <i>NOT SUPPORTED</i>  -->
											</div>

											<div v-if="reorderProduct.items[0].product.productPaperTypeWeight">
												<label for="">Weight</label>
												<select name="" id="" class="form-control" style="margin-bottom:15px;"
													:disabled="true">
													<option
														:value="reorderProduct.items[0].product.productPaperTypeWeight">
														{{ reorderProduct.items[0].product.productPaperTypeWeight }}
													</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Type Weight:</b> <i>NOT SUPPORTED</i>  -->
											</div>
											<div v-if="reorderProduct.items[0].product.material">
												<label for="">Material</label>
												<select name="" id="" class="form-control" style="margin-bottom:15px;"
													:disabled="true">
													<option :value="reorderProduct.items[0].product.material"> {{
														reorderProduct.items[0].product.material }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Type Weight:</b> <i>NOT SUPPORTED</i>  -->
											</div>
											<div v-if="reorderProduct.items[0].product.lamination">
												<label for="">Lamination</label>
												<select name="" id="" class="form-control" style="margin-bottom:15px;"
													:disabled="true">
													<option :value="reorderProduct.items[0].product.lamination"> {{
														reorderProduct.items[0].product.lamination }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Type Weight:</b> <i>NOT SUPPORTED</i>  -->
											</div>

											<div v-if="reorderProduct.items[0].product.foldingOption">
												<label for="">Folding Options</label>
												<select name="" id="" class="form-control" style="margin-bottom:15px;"
													:disabled="true">
													<option :value="reorderProduct.items[0].product.foldingOption"> {{
														reorderProduct.items[0].product.foldingOption }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Folding Options:</b> <i>NOT SUPPORTED</i>  -->
											</div>


											<div v-if="reorderProduct.items[0].product.bookletType">
												<label for="">Booklet Type</label>
												<select name="" id="" class="form-control" style="margin-bottom:15px;"
													:disabled="true">
													<option :value="reorderProduct.items[0].product.bookletType"> {{
														reorderProduct.items[0].product.bookletType }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Booklet Type:</b> <i>NOT SUPPORTED</i>  -->
											</div>

											<div v-if="reorderProduct.items[0].product.bookletPagination">
												<label for="">Booklet Pagination</label>
												<select name="" id="" class="form-control" style="margin-bottom:15px;"
													:disabled="true">
													<option :value="reorderProduct.items[0].product.bookletPagination">
														{{ reorderProduct.items[0].product.bookletPagination }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Booklet Pagination:</b> <i>NOT SUPPORTED</i>  -->
											</div>
											<div v-if="reorderProduct.items[0]">
												<label for="">Order Quantity </label><br>
												<select name="" id="" class="form-control" v-model="orderQuantity">
													<option :value="qs"
														v-for="qs in reorderProduct.items[0].product.quantityGroups"
														:key="qs.quantity">
														{{ qs.quantity }} Units for &euro; {{
															Number(qs.sellingProductPrice).toFixed(0) }}
													</option>
												</select>
											</div>
											<div v-if="reorderProduct.items[0]">
												<label for="">Delivery Turnaround </label><br>
												<p> <b> {{ reorderProduct.items[0].product.deliveryTurnaround }} Days
													</b> </p>
											</div>

											<!-- <button class="btn btn-primary" style="margin:10px 0px;" v-if="needArtwork" @click="needArtwork = false">Need Support for Artwork</button> -->
											<!-- <button class="btn btn-primary" style="margin:10px 0px;" v-else @click="needArtwork = true">I Have Artwork to Upload</button> -->
											<div v-if="mainProduct.artworkInst" style="margin-top:10px;"> <b>Note:</b>
												{{ mainProduct.artworkInst }}</div>
											<div class="" style="padding:10px;border:2px solid lightgray;">
												<p> <b>Your Artwork</b> </p>
												<p>If you are experiencing issues on uploading your artwork or need to discuss issues around your artwork file,  you can email us at artwork@vbs.com and out team will help support on ensuring your artwork is correct.</p>
												<p>Please check our <a href="/artwork-guide" target="_blank"
														style="font-weight:600; text-decoration: underline;">Artwork
														Guide</a> </p>
												<div
													style="border:1px dotted lightgray;padding:10px;position:relative;">
													<div v-if="reorderProduct.items[0]">
														<img :src="$store.state.URL + '/uploads/artworks/' + reorderProduct.items[0].artwork"
															alt="Artwork"
															style="width: 300px; display: block; margin-left: auto; margin-right: auto;">
													</div>
												</div>
											</div>

										</div>
										<div v-else>
											<b> <i> Upload your Design Content<br>
													Include all relevant design
													content for example logos, images, content text and contact details
													<br>
													The VestiGO Design Team will respond with a
													design price within 24 hours<br>
													Our Design Prices start
													from €50.00 for basic design upgrades on print products like
													business cards, flyers
													etc.,' 'For larger design requirements, we will offer a competitive
													design price based on
													review of your design brief
												</i> </b>
										</div>
										<div v-if="product">
											<div v-if="!final">
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> SUB
													TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">&euro; {{
														$LFT(orderQuantity.sellingProductPrice) }}</span>
												</h3>
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> +VAT({{
													product.product.supplierVAT }}%):
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">&euro; {{
														$LFT((orderQuantity.sellingProductPrice *
															product.product.supplierVAT) / 100) }}</span>
												</h3>
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">&euro; {{ $LFT(
														((orderQuantity.sellingProductPrice *
															product.product.supplierVAT) / 100) +
														orderQuantity.sellingProductPrice ) }}</span>
												</h3>
												<h4 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL
													UNITS:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">{{ orderQuantity.quantity }}</span>
												</h4>
											</div>
											<div v-else>
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> SUB
													TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">&euro; {{ orderQuantity.sellingProductPrice }}</span> -->
												</h3>
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> +VAT({{
													product.supplierVAT }}%):
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">&euro; {{  ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) .toFixed(0) }}</span> -->
												</h3>
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">&euro; {{ ( ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) + orderQuantity.sellingProductPrice  ).toFixed(0) }}</span> -->
												</h3>
												<h4 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL
													UNITS:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">{{ orderQuantity.quantity }}</span> -->
												</h4>
											</div>
										</div>
										<button class="btn btn-success" v-if="user" :disabled="bVerify"
											@click="addToCart" style="border-radius:0px;width:100%;margin-top:5px;">ADD
											TO BASKET</button>
										<div v-else>
											<button class="btn btn-primary form-control"
												@click="$store.state.showModal = true">Login/Regsiter</button>


											<!-- <a class="btn btn-success" href="/login"  style="border-radius:0px;width:100%;margin-top:5px;">Login</a>  -->
										</div>
										<p class="text-danger" v-if="error.length">{{ error }}</p>
										<p class="text-success" v-if="success.length">{{ success }}</p>

									</div>
								</div>
							</div>
							<div role="tabpanel" class="tab-pane" id="ideals">
								<div class="col-md-8 col-md-offset-2 col-xs-12 ideals-w">
									<div class="ideal">
										<img src="/images/img-paper.png" alt="ideal 1">
									</div>
									<div class="ideal">
										<img src="/images/product/details/ideal-2.jpg" alt="ideal 2">
									</div>
									<div class="ideal">
										<img src="/images/product/details/ideal-3.jpg" alt="ideal 3">
									</div>
									<div class="ideal">
										<img src="/images/product/details/ideal-4.jpg" alt="ideal 4">
									</div>
								</div>
							</div>
							<div role="tabpanel" class="tab-pane" id="paper">

								<div class="product-image v-middle">
									<div class="col-sm-12">
										<img src="/images/img-paper.png" alt="ideal 1" class="v-middle">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>

		<div style="position:relative;">
			<footer-section></footer-section>
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import { mapState } from 'vuex';
export default {
	watch: {
		product(value) {
			this.loaded = false;
			setTimeout(() => {
				this.loaded = true
			}, 1000);
			// console.log("value of priduct", value)
		},
	},
	computed: {
		...mapState(['user', 'mainProduct', 'subProducts', 'reorderProduct']),
		product() {
			if (this.finalProductsSix.length && this.q.printed.length) {
				console.log("we have a product")
				return this.finalProductsSix[0]
			} else {
				console.log("we have no product")
				return null
			}
		},
		finalProducts() {
			let products = [];
			//sided

			if (this.q.printed.length) {
				this.subProducts.forEach(element => {
					if (element.printed) {
						if (element.printed == this.q.printed) {
							products.push(element)
						}
					} else {
						products.push(element)
					}

				});
			} else {
				products = this.subProducts
			}
			return products;
		},
		finalProductsTwo() {
			//productPaperSizeFormat
			let productsPPSF = [];

			if (this.q.productPaperSizeFormat.length) {
				this.finalProducts.forEach(element => {
					if (element.productPaperSizeFormat == this.q.productPaperSizeFormat) {
						productsPPSF.push(element)
					}
				});
				// productsPPSF = this.finalProducts.filter((item) => { item.productPaperSizeFormat == this.q.productPaperSizeFormat })			
			} else {
				productsPPSF = this.finalProducts
			}
			return productsPPSF
		},
		finalProductsThree() {
			try {
				let productsPPTW = []
				if (this.q.productPaperTypeWeight.length) {
					this.finalProductsTwo.forEach(element => {
						if (element.productPaperTypeWeight == this.q.productPaperTypeWeight) {
							productsPPTW.push(element)
						}
					});
					// productsPPTW = this.finalProductsTwo.filter((item) => { item.productPaperTypeWeight == this.q.productPaperTypeWeight })			
				} else {
					productsPPTW = this.finalProductsTwo
				}
				return productsPPTW
			} catch (error) {
				return this.finalProductsTwo
			}
			//productPaperTypeWeight

		},
		finalProductsFour() {
			//foldingOption
			let productsFO = []
			if (this.q.foldingOption.length) {
				this.finalProductsThree.forEach(element => {
					if (element.foldingOption == this.q.foldingOption) {
						productsFO.push(element)
					}
				});
				// productsFO = this.finalProductsThree.filter((item) => { item.foldingOption == this.q.foldingOption })			
			} else {
				productsFO = this.finalProductsThree
			}
			return productsFO;
		},
		finalProductsFive() {

			//foldingOption
			let productsBP = []
			if (this.q.bookletPagination.length) {
				this.finalProductsFour.forEach(element => {
					if (element.bookletPagination == this.q.bookletPagination) {
						productsBP.push(element)
					}
				});
				// productsBP = this.finalProductsFour.filter((item) => { item.bookletPagination == this.q.bookletPagination })			
			} else {
				productsBP = this.finalProductsFour
			}
			return productsBP
		},
		finalProductsSix() {

			//foldingOption
			let productsBT = []
			if (this.q.bookletType.length) {
				this.finalProductsFive.forEach(element => {
					if (element.bookletType == this.q.bookletType) {
						productsBT.push(element)
					}
				});
				// productsBT = this.finalProductsFive.filter((item) => { item.bookletType == this.q.bookletType })			
			} else {
				productsBT = this.finalProductsFive
			}

			return productsBT

			//   this.subProducts.forEach(element => {

			//   });
		},
		sideProducts() {
			let products = []
			this.subProducts.forEach(element => {
				if (element.printed == this.q.printed) products.push(element)
			});
			return products;
		},
		QproductPaperSizeFormat() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.productPaperSizeFormat.length) names.push(element.productPaperSizeFormat)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		QproductPaperTypeWeight() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.productPaperTypeWeight.length) names.push(element.productPaperTypeWeight)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		QMaterials() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.material.length) names.push(element.material)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		QLaminations() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.lamination.length) names.push(element.lamination)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		final() {
			let error = ''
			if (this.product) {

				if (this.product.product.productPaperSizeFormat.length && this.q.productPaperSizeFormat.length < 1) error = "Please Select a Size"
				if (this.product.product.productPaperTypeWeight.length && this.q.productPaperTypeWeight.length < 1) error = "Please Select a Type Weight"
				if (this.product.product.foldingOption.length && this.q.foldingOption.length < 1) error = "Please Select a Folding Option"
				if (this.product.product.bookletPagination.length && this.q.bookletPagination.length < 1) error = "Please Select a Booklet Pagination"
				if (this.product.product.bookletType.length && this.q.bookletType.length < 1) error = "Please Select a Booklet Type"
			}
			console.log(error)
			return error.length
		},
		bVerify() {
			if (this.orderQuantity == null) {
				return true
			} else {
				return false
			}
		},
		totalOrderPrice() {
			if (this.product && this.orderQuantity > 0) {
				return (this.product.sellingProductPrice / this.product.quantity) * Number(this.orderQuantity)
			} else {
				if (this.selectedPackage == null) {
					return 0
				} else {
					return this.selectedPackage.price
				}
			}
		},
		VAT() {
			if (this.product && this.totalOrderPrice > 0) {
				return ((this.totalOrderPrice * this.product.supplierVAT) / 100)
			} else {
				return 0
			}
		},
		totalOrderUnits() {
			if (this.orderQuantity > 0) {
				return this.orderQuantity
			} else {
				if (this.selectedPackage == null) {
					return 0
				} else {
					return this.selectedPackage.quantity
				}
			}
		},
	},
	components: { NavBar, FooterSection },
	methods: {
		upSide(side) {
			console.log("updating side", side)
			this.q.printed = side
		},
		upOrient(side) {
			console.log("updating orient", side)
			this.q.orient = side
		},
		upMaterial(side) {
			console.log("updating material", side)
			this.q.material = side
		},


		goTo(name) {
			this.$router.push({ name })
		},
		handle(event) {
			this.images = []
			this.allFiles = []
			this.allFiles = event.target.files[0]
			console.log(event, this.allFiles)
			const reader = new FileReader();
			reader.readAsDataURL(this.allFiles);
			reader.onload = () => {
				this.images.push(reader.result)
			};

			// this.allFiles.forEach((element) => {

			// })


		},
		async startUpload() {
			if (this.allFiles !== null) {
				let name = Date.now() + this.allFiles.name
				const formData = new FormData();
				formData.append('encType', 'multipart/form-data');

				formData.append('artworks', this.allFiles, name)
				console.log(formData)
				//   let DD = Date.now()
				let dec = await this.$store.dispatch("uploadFilesArtworks", formData)
				return name
			} else {
				return null
			}

			console.log(dec)
			//   if(dec.status){
			//     this.files = [] ;
			//     this.allFiles = [];
			//     this.images = [];
			//   }
			// this.$store.dispatch("getProductImages")


		},
		async cartSuccess() {
			this.selectedPackage = null;
			this.orderQuantity = null;
			this.success = "Product Has Been Added to Cart";
			await this.$store.dispatch("getUser");
			location.replace("/checkout")
			// this.$store.state.uploadProgress = 0;
			// this.allFiles = null
		},
		async addToCart() {
			this.error = ''

			let fileName = (this.allFiles !== null && this.needArtwork) ? await this.startUpload() : null;
			// if (this.product.product.productPaperSizeFormat.length  && this.q.productPaperSizeFormat.length < 1) this.error = "Please Select a Size"
			// if (this.product.product.productPaperTypeWeight.length  && this.q.productPaperTypeWeight.length < 1) this.error = "Please Select a Type Weight"
			// if (this.product.product.foldingOption.length  && this.q.foldingOption.length < 1) this.error = "Please Select a Folding Option"
			// if (this.product.product.bookletPagination.length  && this.q.bookletPagination.length < 1) this.error = "Please Select a Booklet Pagination"
			// if (this.product.product.bookletType.length  && this.q.bookletType.length < 1) this.error = "Please Select a Booklet Type"
			// return]
			if (this.orderQuantity !== null && this.error.length < 1) {
				let opData = {
					productPaperSizeFormat: this.q.productPaperSizeFormat,
					productPaperTypeWeight: this.q.productPaperTypeWeight,
					foldingOption: this.q.foldingOption,
					bookletPagination: this.q.bookletPagination,
					bookletType: this.q.bookletType,
					material: this.q.material,
					lamination: this.q.lamination,
					printed: this.q.printed
				}

				// return
				let cartItem = {
					artwork: this.reorderProduct.items[0].artwork,
					opData,
					product: this.reorderProduct.items[0].product._id,
					user: this.user._id,
					isPacakgeUsed: false,
					quantity: this.orderQuantity
				}

				let dec = await this.$store.dispatch("addCartItem", cartItem)
				if (dec.status) {
					this.cartSuccess()
				}
			} else {
				this.error = (this.error.length) ? this.error : "Please add any sufficient quantity/amount in item first";
			}
		},
		drop(event) {
			console.log(event)
		}
	},
	// watch:{
	// 	q:{
	// 		handler(value){
	// 			let product = null;
	// 			this.subProducts.forEach(element => {
	// 				if(value.printed.length){
	// 					if(element.printed == value.printed) product = element
	// 				}
	// 			});
	// 			this.product = product
	// 		},
	// 		deep: true
	// 	},
	// },
	async mounted() {
		console.log(this.$route)
		await this.$store.dispatch("getReorderProductById", { id: this.$route.params.id });
		this.q.material = this.mainProduct.materials[0]
		if (this.mainProduct.printed.length < 2) {
			if (this.mainProduct.printed[0] == '') {
				this.q.printed = 'Not Supported'
			} else {
				this.q.printed = this.mainProduct.printed[0]
			}
		}

	},
	data: () => {
		return {
			loaded: true,
			orientNew: "Landscape",
			q: {
				printed: "",
				orient: "Landscape",
				material: "",
				lamination: "",
				productPaperSizeFormat: "",
				productPaperTypeWeight: "",
				foldingOption: "",
				bookletPagination: "",
				bookletType: ""
			},
			files: [],
			allFiles: null,
			images: [],
			needArtwork: true,
			productPaperSizeFormat: "",
			productPaperTypeWeight: "",
			foldingOption: "",
			bookletPagination: "",
			bookletType: "",
			error: "",
			success: "",
			orderQuantity: null,
			selectedPackage: null,
			lamination: 'MATT LAMINATION',
			side: "DOUBLE SIDE",
			quantity: [
				20, 50, 100, 150, 200, 250, 300, 500, 1000, 2000, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000
			],
			sizes: [
				"55x85mm",
				"DL (210x99mm)",
				"A7 (74x105mm)",
				"A6 (105x148mm)",
				"A5 (148x210mm)",
				"A4 (210x297mm)",
				"A3 (297x420mm)",
				"A2 (420x524mm)",
				"A1 (594x841mm)",
				"A0 (841x1189mm)"
			],
			bPTypes: [
				"8pp", "44pp",
				"12pp", "48pp",
				"16pp", "52pp",
				"20pp", "56pp",
				"24pp", "60pp",
				"28pp", "64pp",
				"32pp",
				"36pp",
				"40pp"
			],
			bTypes: [
				"A5 Booklet Self Cover",
				"A5 Booklet with Heavy Cover",
				"A5 Booklet with Heavy Cover with Matt Lamination",
				"A5 Booklet with Heavy Cover with Gloss Lamination",
				"A4 Booklet Self Cover",
				"A4 Booklet with Heavy Cover",
				"A4 Booklet with Heavy Cover with Matt Lamination",
				"A4 Booklet with Heavy Cover with Gloss Lamination"
			],
			fOptions: [
				"A6 fold A7 (half fold)",
				"A5 fold A6 (half fold)",
				"198x210mm fold 99x210mm (half fold)",
				"A4 fold A5 (half fold) ",
				"6pp DL – (A4 roll fold DL) ",
				"6pp DL – (A4 Z fold DL) ",
				"210x396mm fold 210x198mm (half fold)",
				"210x396mm fold 210x99mm (Z fold)",
				"210x396mm fold 210x99mm (roll fold)",
				"A3 fold A4 (half fold) ",
				"A3 – A5 (cross fold)",
				"297x140mm (Z fold)",
				"297x140mm (roll fold)",
				"297x105mm (Z fold)",
				"297x105mm (roll fold)",
				"6pp A5 (roll fold)",
				"6pp A5 (Z fold)",
				"6pp A4 (roll fold)",
				"6pp A4 (Z fold)",
				"A2 fold A3 (half fold) ",
				"A2 to 210x99mm (map fold)"
			],
			types: [
				"130gsm Silk",
				"130gsm Gloss",
				"120gsm Bond / Offset",
				"170gsm Silk ",
				"170gsm Gloss",
				"250gsm Gloss",
				"300gsm Bond",
				"300gsm Silk",
				"350gsm Silk",
				"400gsm Silk",
				"400gsm Silk with Matt lamination",
				"400gsm Silk with Gloss Lamination"
			],
		}
	}

}
</script>

<style>
.v-input {
	position: absolute;
	z-index: 9999;
	height: 100%;
	border: 2px solid red;
	width: 100%;
	opacity: 0;
}
</style>