<template>
     <div> 
	  <header>
		<auth-comp></auth-comp>		
		<!--Main Header: Begin-->
		<section class="main-header" id="ttttttt">
			<div class="container">
				<div class="row">
					<div class="col-lg-2 col-md-2 col-sm-4 col-xs-5" style="padding-right:5px;">
						<div class="logo hd-pd " style="padding-top:0px;">
							<a href="/">
								<img src="/images/logo.png" alt="printshop logo" style="max-height:75px;">
							</a>
						</div>	
					</div>
					<div class="col-md10 visible-md visible-lg tt-layer">
						<nav id="main-menu" class="main-menu clearfix">
							<ul>
								<li class="level0 parent col1 all-product hd-pd">
									<a href="#"><span>All Products</span><i class="fa fa-chevron-down"></i></a>
									<ul class="level0">
										
										<li class="level1">
											<span class="menu-title">Categories</span>
											<ul class="level1" >
												<li class="level2" v-for="(cat, index) in categories" :key="cat._id">
													<a :href="'/category/'+cat.slug" :title="cat.name">{{ cat.name }}</a>
													<!-- <ul class="level2"> -->
														<!-- <li><span class="menu-title">{{ cat.name }}</span></li> -->
														<!-- <li style="display: block;width: 100%;" v-for="subcat in subCategories" v-if="subcat.category._id == cat._id" :key="subcat._id">
															<a :href="'/category/'+subcat.slug" :title="subcat.name">{{ subcat.name }}</a>
														</li>
														
													</ul> -->
												</li>
											</ul>
										</li>
									</ul>
								</li>

								<li class="level0 hd-pd">
									<a href="/services" title="Postcards">Services</a>
								</li> 
								<li class="level0 hd-pd" title="About Us">
									<a href="/about-us">About Us</a>
								</li>
								<li class="level0 hd-pd" title="About Us">
									<a href="/quotes-form">Quotes</a>
								</li>
								<li class="level0 hd-pd" title="How To Order Online">
									<a href="/how-to-order-online">How To Order</a>
								</li>
								<li class="level0 hd-pd" title="Artwork Guide">
									<a href="/artwork-guide">Artwork Guide</a>
								</li>
								<li class="level0 hd-pd" style="float: right;width: 200px;">
									<div class="cart-w SC-w hd-pd " v-if="user">
							<span class="mcart-icon dropdowSCIcon" style="padding: 0px 26px;float: right;">
								<i class="fa fa-shopping-cart"></i>
								<span class="mcart-dd-qty">{{ userCartItems.length }}</span>
							</span>
							<div class="mcart-dd-content dropdowSCContent clearfix">
								<div class="mcart-item-w clearfix1">
									<ul>
										<li class="mcart-item" v-for="item in userCartItems" :key="item._id">
											<!-- <img :src="$store.state.URL+'/uploads/product/'+item.product.image" alt="postcard cards" style="width:55px;" v-if="item.product">
											<img :src="$store.state.URL+'/uploads/supplier-quotes/'+item.quote_id.quoteSupplierImage" alt="postcard cards" style="width:55px;" v-else> -->
											<div class="mcart-info">
												<!-- <a :href="'/product/' + (item.product ? item.product.slug : item.quote_id.quotesProduct)" class="mcart-name">
													{{ item.product ? item.product.productName : item.quote_id.quotesProduct }}
												</a> -->
												<!-- <a :href="'/product/'+item.product.slug" class="mcart-name">{{ item.product.productName }}</a> -->
												<span class="mcart-qty"> {{ item.quantity.quantity }} Units </span><br>
												<span class="mcart-price">&euro;{{ Number(item.quantity.sellingProductPrice).toFixed(0) }}</span>
												<span class="mcart-remove-item" @click="removeCartItem(item)">
													<i class="fa fa-times-circle"></i>
												</span>
											</div>
										</li>
									</ul>
								</div>
                                      
								<div class="mcart-total clearfix">
									<table>
										<tr>
											<td>Sub-Total</td>
											<td>&euro;{{ $LFT(this.subTotal)  }}</td>
										</tr>
										
										<tr>
											<td>VAT </td>
											<td>&euro;{{ $LFT(this.VAT) }}</td>
										</tr>
										<tr class="total">
											<td>Total</td>
											<td>&euro;{{ $LFT(this.total) }}</td>
										</tr>
									</table>
								</div>
								<div class="mcart-links buttons-set clearfix">
									<a href="/cart" class="gbtn mcart-link-cart">View Cart</a>
									<a href="/checkout" class="gbtn mcart-link-checkout">Checkout</a>
								</div>
							</div>
						</div>
						<div class="cart-w SC-w hd-pd ">
							<span class="mcart-icon dropdowSCIcon">
								<i class="fa fa-user"></i>
								<span style="font-weight: 700;font-size: 14px;" v-if="user"> {{ user.name }}</span>
								<span style="font-weight: 700;font-size: 14px;" v-else> SIGN IN </span>
							</span>
							<div class="mcart-dd-content dropdowSCContent clearfix" id="md-list-user">
								<a  v-if="user == null" @click="$store.state.showModal = true" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Login</span> </a>

								<a href="/user-dashboard/refs" v-if="refsUserData == null || refsUserData ==''" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Global Partner</span> </a>
								<a href="/user-dashboard/refs" v-if="refsUserData != null && refsUserData !=''" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Reseller {{ refsUserData.data.name }}</span> </a>
								<!-- <a  v-if="user == null" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Register</span> </a> -->

								<a href="/user-dashboard" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Account</span> </a>
								<a href="/user-dashboard/invoices" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-file-text"></i>  </span> <span class="mt">Invoices</span> </a>
								<a href="/user-dashboard/jobs" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-info-circle"></i>  </span> <span class="mt">Job Status</span> </a>
								<a href="/user-dashboard/quotes" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-file-text"></i>  </span> <span class="mt">My Quotes</span> </a>
								<a href="/user-dashboard/addresses" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-map-marker"></i>  </span> <span class="mt">Addresses</span> </a>
								<span v-if="user !== null" class="m-list-so" @click="logout"> <span class="mic"> <i class="fa fa-sign-out"></i>  </span> <span class="mt">Logout</span> </span>
							</div>
						</div>
								</li>
							</ul>
						</nav>
					</div>
					<div class="col-sm-1 col-sm-offset-5 col-xs-offset-2 col-xs-2 visible-sm visible-xs mbmenu-icon-w">
						<span class="mbmenu-icon hd-pd" @click="$store.state.navMenu = !$store.state.navMenu">
							<i class="fa fa-bars"></i>
						</span>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-2 col-xs-3" style="display: none;">
						<div class="cart-w SC-w hd-pd " v-if="user">
							<span class="mcart-icon dropdowSCIcon" style="padding: 0px 26px;float: right;">
								<i class="fa fa-shopping-cart"></i>
								<span class="mcart-dd-qty">{{ userCartItems.length }}</span>
							</span>
							<div class="mcart-dd-content dropdowSCContent clearfix">
								<div class="mcart-item-w clearfix1">
									<ul>
										<li class="mcart-item" v-for="item in userCartItems" :key="item._id">
											<!-- <img :src="$store.state.URL+'/uploads/product/'+item.product.image" alt="postcard cards" style="width:55px;" v-if="item.product">
											<img :src="$store.state.URL+'/uploads/supplier-quotes/'+item.quote_id.quoteSupplierImage" alt="postcard cards" style="width:55px;" v-else> -->
											<div class="mcart-info">
												<!-- <a :href="'/product/' + (item.product ? item.product.slug : item.quote_id.quotesProduct)" class="mcart-name">
													{{ item.product ? item.product.productName : item.quote_id.quotesProduct }}
												</a> -->
												<!-- <a :href="'/product/'+item.product.slug" class="mcart-name">{{ item.product.productName }}</a> -->
												<span class="mcart-qty"> {{ item.quantity.quantity }} Units </span><br>
												<span class="mcart-price">&euro;{{ Number(item.quantity.sellingProductPrice).toFixed(0) }}</span>
												<span class="mcart-remove-item" @click="removeCartItem(item)">
													<i class="fa fa-times-circle"></i>
												</span>
											</div>

											<!-- <img :src="$store.state.URL+'/uploads/product/'+item.product.image" alt="postcard cards" style="width:55px;">
											<div class="mcart-info">
												<a :href="'/product/'+item.product.slug" class="mcart-name">{{ item.product.productName }}</a>
												<span class="mcart-qty"> {{ item.quantity.quantity }} Units </span><br>
												<span class="mcart-price">&euro;{{ Number(item.quantity.sellingProductPrice).toFixed(0) }}</span>
												<span class="mcart-remove-item" @click="removeCartItem(item)">
													<i class="fa fa-times-circle"></i>
												</span>
											</div> -->
										</li>
									</ul>
								</div>
                                      
								<div class="mcart-total clearfix">
									<table>
										<tr>
											<td>Sub-Total</td>
											<td>&euro;{{this.subTotal.toFixed(0)  }}</td>
										</tr>
										
										<tr>
											<td>VAT </td>
											<td>&euro;{{ this.VAT.toFixed(0) }}</td>
										</tr>
										<tr class="total">
											<td>Total</td>
											<td>&euro;{{ this.total.toFixed(0) }}</td>
										</tr>
									</table>
								</div>
								<div class="mcart-links buttons-set clearfix">
									<a href="/cart" class="gbtn mcart-link-cart">View Cart</a>
									<a href="/checkout" class="gbtn mcart-link-checkout">Checkout</a>
								</div>
							</div>
						</div>
						<div class="cart-w SC-w hd-pd ">
							<span class="mcart-icon dropdowSCIcon">
								<i class="fa fa-user"></i>
								<span style="font-weight: 700;font-size: 14px;" v-if="user"> {{ user.name }}</span>
								<span style="font-weight: 700;font-size: 14px;" v-else> SIGN IN </span>
							</span>
							<div class="mcart-dd-content dropdowSCContent clearfix" id="md-list-user">
								<a  v-if="user == null" @click="$store.state.showModal = true" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Login</span> </a>
								
								
								<a href="/user-dashboard/refs" v-if="refsUserData == null || refsUserData ==''" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Global Partner</span> </a>
								<a href="/user-dashboard/refs" v-if="refsUserData != null && refsUserData !=''" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Reseller {{ refsUserData.data.name }}</span> </a>
								<!-- <a  v-if="user == null" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Register</span> </a> -->

								<a href="/user-dashboard" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-user"></i>  </span> <span class="mt">Account</span> </a>
								<a href="/user-dashboard/invoices" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-file-text"></i>  </span> <span class="mt">Invoices</span> </a>
								<a href="/user-dashboard/jobs" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-info-circle"></i>  </span> <span class="mt">Job Status</span> </a>
								<a href="/user-dashboard/quotes" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-file-text"></i>  </span> <span class="mt">My Quotes</span> </a>
								<a href="/user-dashboard/addresses" v-if="user !== null" class="m-list"> <span class="mic"> <i class="fa fa-map-marker"></i>  </span> <span class="mt">Addresses</span> </a>
								<span v-if="user !== null" class="m-list-so" @click="logout"> <span class="mic"> <i class="fa fa-sign-out"></i>  </span> <span class="mt">Logout</span> </span>
							</div>
						</div>
						
					</div>
					
				</div>
			</div>
		</section><!--Main Header: End-->
	</header><!--Header: End-->
	<m-menu></m-menu>
</div>
</template>

<script>
import AuthComp from "@/components/AuthComp.vue"
import MMenu from '@/views/MMenu.vue'

import {mapState} from 'vuex'
export default {
	components:{AuthComp,MMenu},
	computed:{
		...mapState(['categories','user','userCart','userCartItems','products','mainProducts','subCategories','refsUserData']),
		subTotal(){
			if (this.userCartItems) {
				let sub = 0;
				this.userCartItems.forEach(element => {
					sub = sub + element.quantity.sellingProductPrice
				});
				return sub
			}else{
				return 0
			}
		},
		VAT(){
			if (this.userCartItems) {
				let vat = 0;
				this.userCartItems.forEach(element => {
					let vatOrPrice = element.product !== undefined && element.product !== null
						? element.product.product.supplierVAT
						: element.quantity.supplierVATNumber !== undefined && element.quantity.supplierVATNumber !== null
						? element.quantity.supplierVATNumber
						: 0;

						vat = vat + ((element.quantity.sellingProductPrice * vatOrPrice) / 100)
					// vat = vat + ((element.quantity.sellingProductPrice * element.product.product.supplierVAT) / 100)
				});
				return vat
			}else{
				return 0
			}
		},
		total(){
			return (this.subTotal + this.VAT)
			// return (this.subTotal + this.VAT)
		},
		route(){
			// console.log($route)
			return this.$route
		},
		vw(){
			const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			console.log(vw)
			return vw
		}
	},
data(){
	return {
		display:false
	}
},
methods:{
	async removeCartItem(item){
		console.log("removing cart item", item)
		await this.$store.dispatch("removeCartItem",{item})
		await this.$store.dispatch("getUser")
		
	},
	logout(){
		this.$store.dispatch("logout")
	},
	alterDisplay(){
		
	}
},
mounted() {
		this.$store.dispatch("refsUserDataAPI");
		
	},
}
</script>

<style scoped>
/* * {
  font-family: "Poppins", sans-serif;
} */
.search-safarii{
	overflow: hidden;
    top: 60px;
    width: 265px;
    height: 60px;
    position: absolute;
    right: -9px;
    z-index: 99999;
}
#pgc-3484-0-1 {
}
.panel-grid-cell {
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.wd_search_product {
    width: 40%;
    float: left;
	max-width: 190px;
    height: 45px;
    background: #424449;
    color: #fff;
    padding-left: 15px;
    border: none;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #636363;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}
.wd_search_form {
    position: relative;
    width: 60%;
	max-width:500px;
    float: left;
    margin-top: 15px;
    margin-bottom: 15px;
}
.printshop-minicart {
    background: #ff9c00 url(../../public/images/icon-cart.png) 96% center no-repeat;
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #fff;
    width: 21%;
    float: left;
    padding-left: 15px;
    line-height: 45px;
    overflow: hidden;
}
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
}

.screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px;
    word-wrap: normal!important;
}
.screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    word-wrap: normal!important;
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    overflow-wrap: normal!important;
    padding: 0;
    position: absolute!important;
    width: 1px;
}
.screen-reader-text {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal!important;
}
.wd_search_form input[type~="text"] {
    height: 45px;
    padding: 0 15px;
    width: 100%;
    background: #424449;
    color: #fff !important;
    border-color: #636363;
	border-style: double;
}
.wd_search_form input[type~="submit"] {
    position: absolute;
    right: 10px;
    top: 10px;
    background: url(../../public/images/boxed09-i-search.png) no-repeat center;
    border: none;
    overflow: hidden;
    text-indent: 115px;
    width: 45px;
}
#s::placeholder{
	color: white;
	opacity: 1;
}
.sssss{
	background-color: rgb(255, 255, 255) !important;
    color: black;
    line-height: 44px;
    padding: 0px 0px 0px 14px;
    /* display: block !important; */
    width: 254px;
    border-bottom: 1px solid lightgray;
    /* margin-top: 11px;			 */
}
#main-menu a, #main-menu > ul > li > a span {
    font-weight: 600;
} 
.menu-fixed .tt-layer{
	padding-top: 10px;
} 
.m-list, .m-list-so{
	display: block;
}
.m-list .mic{display: inline-block;margin-left:25px;margin-right:5px;margin-bottom:10px;margin-top:10px;}
.m-list-so .mic{display: inline-block;margin-left:25px;margin-right:5px;margin-bottom:10px;margin-top:10px;}
.m-list-so .mic i {color: black !important;}
.m-list .mt{display: inline-block;margin-left:5px;margin-right:5px;}
#md-list-user{
	width: 160px !important;
}
.cart-w:hover .mcart-dd-content {
    top: 65px !important;}
</style>