<template>
	<div v-if="mainProduct">
		<nav-bar></nav-bar>

		<main class="main" id="product-detail">
			<!--Breadcrumb : Begin-->

			<section class="product-info-w position-relative">
				<div class="container">


					<div class="row">
						<div class="tab-content clearfix">
							<div role="tabpanel" class="row tab-pane active" id="features">
								<div class="col-md-3 p-0">
									<aside id="sidebar_cate" class="" v-if="vw > 480">
										<h3 class="sidebar-title">Category</h3>
										<ul id="cate_list" class="cate_list">
											<li class="level0 parent" v-for="cat in categories" :key="cat.id">
												<a href="javascript:void(0)" title="Business Cards">
													<span>{{ cat.name }}</span>
													<i class="fa" :class="isDisplayed(cat._id) ? 'fa-minus' : 'fa-plus'"
														@click="toggleDisplay(cat._id)"></i>
												</a>
												<ul class="level0" :id="cat._id" v-show="isDisplayed(cat._id)">
													<template v-for="subcat in subCategories">
														<template v-if="subcat.category._id == cat._id">
															<li class="level1 nav-1-1 first item"
																v-for="mainPro in mainProductsAll"
																v-if="mainPro.category == subcat.name"
																@click="goTo('CustomProduct', { id: mainPro._id })">
																<a href="javascript:void(0)"
																	:title="mainPro.productName">
																	{{ mainPro.productName }}
																</a>
															</li>
														</template>
													</template>
												</ul>
											</li>
										</ul>


										<!-- <div class="category-left-banner">
											<a href="#" title="category left banner">
												<img src="/images/banner/category/left-banner.jpg" alt="Left banner">
											</a>
										</div> -->
										<div style="margin-top: 25%;">
											<div class="card-img">
												<img src="/images/testimonials/quotes_slider.png" alt="">
											</div>
											<div class="card-text md-3">
												<p>
													Product or Service not listed here OR you need Graphic Design or Web
													Design support, no problem, just click on <a style="color: red;"
														href="Javascript:void(0)" @click="goTo('Quotes-form')">QUOTES</a>
													and let our team of experts help you
													with your requirements. Upload any supporting images or files. Our team
													will respond with a competitive quote within 24 – 72 hours.
												</p>
											</div>
										</div>
									</aside>
								</div>
								<div class="product-image col-md-4">
									<!-- <div v-if="loaded"> -->
									<!-- <div class="col-sm-12 col-xs-12" v-if="product">
											<img :src="$store.state.URL + '/uploads/product/' + product.image"
												alt="ideal 1">
										</div> -->
									<div class="col-sm-12 col-xs-12">
										<img :src="$store.state.URL + '/uploads/product/' + mainProduct.image"
											alt="ideal 1">
									</div>
									<div class="col-sm-12">
										<a href="javascript:void(0)" @click="goTo('HowToOrderOnline')">
											<b style="color: red;">How to Order Online ? Click here to view </b>
										</a>
									</div>
									<div class="col-sm-12 col-xs-12">
										<p><br>{{ mainProduct.productMktDesc }}</p>
									</div>
									<!-- </div> -->
								</div>
								<div class="product-shortdescript v-middle col-md-5" v-if="mainProduct">
									<div class="col-sm-12 col-xs-12">
										<!-- here -->
										<div class="" v-if="needArtwork">
											<h3 style="margin-bottom:15px;">{{ mainProduct.productName }}</h3>
											<div class="col-12" style="margin-bottom:15px;">
												<div class="rating-box">
													<i class="fa fa-star"></i>
													<i class="fa fa-star"></i>
													<i class="fa fa-star-half-o"></i>
													<i class="fa fa-star-o"></i>
													<i class="fa fa-star-o"></i>
													<span>(23 Customer Reviews)</span>
												</div>
											</div>
											<!-- <p v-if="product !== null">
											{{ product.productDesc }}
										</p> -->



											<div v-if="mainProduct.printed.length > 1">
												<label for="" class="d-block">Print</label>
												<select name="" id="" class="form-control" v-model="q.printed">
													<option value="Single Side">Single Side</option>
													<option value="Double Side">Double Side</option>
												</select>

											</div>
											<div v-else>
												<div v-if="mainProduct.printed[0] !== ''">
													<label for="" class="d-block">Print</label>
													<select name="" id="" class="form-control">
														<option :value="mainProduct.printed[0]">{{
															mainProduct.printed[0] }}</option>
													</select>
												</div>

											</div>

											<div v-if="product && product.orient == 'Optional'">
												<label for="" class="d-block">Orientation</label>

												<select name="" id="" class="form-control" v-model="orientNew">
													<option value="Portrait"> Portrait</option>
													<option value="Landscape"> Landscape</option>
												</select>

											</div>
											<div v-if="mainProduct.productPaperSizeFormat.length">
												<label for="">Size</label>
												<select name="" id="" class="form-control"
													v-model="q.productPaperSizeFormat" style="margin-bottom:15px;">
													<option v-for="size in QproductPaperSizeFormat" :key="size"
														:value="size">{{ size }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Size Format:</b> <i>NOT SUPPORTED</i>  -->
											</div>

											<div v-if="mainProduct.productPaperTypeWeight.length">
												<label for="">Weight</label>
												<select name="" id="" class="form-control"
													v-model="q.productPaperTypeWeight" style="margin-bottom:15px;">
													<option v-for="ty in QproductPaperTypeWeight" :key="ty" :value="ty">
														{{ ty }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Type Weight:</b> <i>NOT SUPPORTED</i>  -->
											</div>
											<div v-if="mainProduct.materials.length">
												<label for="">Material</label>
												<select name="" id="" class="form-control" v-model="q.material"
													style="margin-bottom:15px;">
													<option v-for="ty in QMaterials" :key="ty" :value="ty">{{ ty }}
													</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Type Weight:</b> <i>NOT SUPPORTED</i>  -->
											</div>
											<div v-if="mainProduct.laminations.length">
												<label for="">Lamination</label>
												<select name="" id="" class="form-control" v-model="q.lamination"
													style="margin-bottom:15px;">
													<option v-for="ty in QLaminations" :key="ty" :value="ty">{{ ty
													}}
													</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Product Paper Type Weight:</b> <i>NOT SUPPORTED</i>  -->
											</div>

											<div v-if="mainProduct.foldingOption.length">
												<label for="">Folding Options</label>
												<select name="" id="" class="form-control" v-model="q.foldingOption"
													style="margin-bottom:15px;">
													<option v-for="ty in mainProduct.foldingOption" :key="ty"
														:value="ty">{{ ty }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Folding Options:</b> <i>NOT SUPPORTED</i>  -->
											</div>


											<div v-if="mainProduct.bookletType.length">
												<label for="">Booklet Type</label>
												<select name="" id="" class="form-control" v-model="q.bookletType"
													style="margin-bottom:15px;">
													<option v-for="ty in mainProduct.bookletType" :key="ty" :value="ty">
														{{ ty }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Booklet Type:</b> <i>NOT SUPPORTED</i>  -->
											</div>

											<div v-if="mainProduct.bookletPagination.length">
												<label for="">Booklet Pagination</label>
												<select name="" id="" class="form-control" v-model="q.bookletPagination"
													style="margin-bottom:15px;">
													<option v-for="ty in mainProduct.bookletPagination" :key="ty"
														:value="ty">{{ ty }}</option>
												</select>
											</div>
											<div v-else>
												<!-- <b>Booklet Pagination:</b> <i>NOT SUPPORTED</i>  -->
											</div>
											<div v-if="q.printed.length && product !== null">
												<label for="">Order Quantity </label><br>
												<select name="" id="" class="form-control" v-model="orderQuantity">
													<option :value="qs" v-for="qs in product.quantityGroups"
														:key="qs.quantity">
														{{ qs.quantity }} Units for &euro; {{
															Number(qs.sellingProductPrice).toFixed(0) }}
													</option>
												</select>
											</div>
											<div v-if="product !== null">
												<label for="">Delivery Turnaround </label><br>
												<p> <b> {{ product.deliveryTurnaround }} Days </b> </p>
											</div>
											<!-- <button class="btn btn-primary" style="margin:10px 0px;" v-if="needArtwork" @click="needArtwork = false">Need Support for Artwork</button> -->
											<!-- <button class="btn btn-primary" style="margin:10px 0px;" v-else @click="needArtwork = true">I Have Artwork to Upload</button> -->
											<div v-if="mainProduct.artworkInst" style="margin-top:10px;">
												<b>Note:</b>
												{{ mainProduct.artworkInst }}
											</div>
											<div class="" style="padding:10px;border:2px solid lightgray;"
												v-if="needArtwork && mainProduct.nonPrint != 'Non Print'">
												<p> <b>Upload Your Artwork</b> </p>
												<p>If you are experiencing issues on uploading your artwork or need to
													discuss issues around your
													artwork file, you can email us at artwork@vbs.com and out team will
													help support on ensuring your
													artwork is correct.</p>
												<p>Please check our <a href="/artwork-guide" target="_blank"
														style="font-weight:600; text-decoration: underline;">Artwork
														Guide</a> </p>
												<div
													style="border:1px dotted lightgray;padding:10px;position:relative;">
													<input type="file" name="" id="" class="v-input" @drop="drop"
														@change="handle">

													<img src="/images/download.png" alt="" class="" srcset=""
														style="width:110px;display: block; margin-left:auto; margin-right:auto;">
													<p>Click here to choose the files or drag them here</p>
												</div>
												<div>
												</div>
												<div v-if="$store.state.uploadProgress">
													<b>Uploading:</b> <span> {{ $store.state.uploadProgress }}%
													</span>
												</div>
												<div v-if="allFiles">
													{{ allFiles.name }}
												</div>
											</div>
											<div v-else>
												<h4 v-if="mainProduct.nonPrint != 'Non Print'">
													Email our Design Team with your order number after completing
													the
													order to design@vestigoprint.com if you require design support.
													Our
													team will respond with design price that begins @ €50.00 for
													basic
													artwork print support, and we will let you design pricing for
													more
													complicated print artwork.
												</h4>
											</div>

										</div>
										<div v-else>
											<b> <i> Upload your Design Content<br> Include all relevant design
													content
													for example logos, images, content text and contact details <br>
													The
													VestiGO Design Team will respond with a design price within 24
													hours<br> Our Design Prices start from €50.00 for basic design
													upgrades on print products like business cards, flyers etc.,'
													'For
													larger design requirements, we will offer a competitive design
													price
													based on review of your design brief </i> </b>
										</div>
										<div v-if="product">
											<div v-if="!final">
												<h3
													style="margin-bottom:0px;margin-top:5px;text-align:right;font-size: 17px;">
													SUB TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">&euro; {{
														$LFT(orderQuantity.sellingProductPrice) }}</span>

												</h3>
												<h3
													style="margin-bottom:0px;margin-top:5px;text-align:right;font-size: 17px;">
													+VAT({{
														product.product.supplierVAT }}%):
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">&euro; {{
														$LFT((orderQuantity.sellingProductPrice *
															product.product.supplierVAT) / 100) }}</span>
												</h3>
												<h3
													style="margin-bottom:0px;margin-top:5px;text-align:right;font-size: 17px;">
													TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">&euro; {{ $LFT(
														((orderQuantity.sellingProductPrice *
															product.product.supplierVAT) / 100) +
														orderQuantity.sellingProductPrice) }}</span>
												</h3>
												<h4 style="margin-bottom:0px;margin-top:5px;text-align:right;">
													TOTAL
													UNITS:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<span v-if="orderQuantity">{{ orderQuantity.quantity }}</span>
												</h4>
											</div>
											<div v-else>
												<h3
													style="margin-bottom:0px;margin-top:5px;text-align:right;font-size: 17px;">
													SUB TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">&euro; {{ orderQuantity.sellingProductPrice }}</span> -->
												</h3>
												<h3
													style="margin-bottom:0px;margin-top:5px;text-align:right;font-size: 17px;">
													+VAT({{
														product.supplierVAT }}%):
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">&euro; {{  ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) .toFixed(0) }}</span> -->
												</h3>
												<h3
													style="margin-bottom:0px;margin-top:5px;text-align:right;font-size: 17px;">
													TOTAL:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">&euro; {{ ( ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) + orderQuantity.sellingProductPrice  ).toFixed(0) }}</span> -->
												</h3>
												<h4 style="margin-bottom:0px;margin-top:5px;text-align:right;">
													TOTAL
													UNITS:
													<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
													<!-- <span v-if="orderQuantity">{{ orderQuantity.quantity }}</span> -->
												</h4>
											</div>
										</div>
										<button class="btn btn-success" v-if="user" :disabled="bVerify"
											@click="addToCart" style="border-radius:0px;width:100%;margin-top:5px;">ADD
											TO BASKET</button>
										<div v-else>
											<button class="btn btn-primary form-control"
												@click="$store.state.showModal = true">Login/Regsiter</button>


											<!-- <a class="btn btn-success" href="/login"  style="border-radius:0px;width:100%;margin-top:5px;">Login</a>  -->
										</div>
										<p class="text-danger" v-if="error.length">{{ error }}</p>
										<p class="text-success" v-if="success.length">{{ success }}</p>

									</div>
								</div>
							</div>
							<div role="tabpanel" class="tab-pane" id="ideals">
								<div class="col-md-8 col-md-offset-2 col-xs-12 ideals-w">
									<div class="ideal">
										<img src="/images/img-paper.png" alt="ideal 1">
									</div>
									<div class="ideal">
										<img src="/images/product/details/ideal-2.jpg" alt="ideal 2">
									</div>
									<div class="ideal">
										<img src="/images/product/details/ideal-3.jpg" alt="ideal 3">
									</div>
									<div class="ideal">
										<img src="/images/product/details/ideal-4.jpg" alt="ideal 4">
									</div>
								</div>
							</div>
							<div role="tabpanel" class="tab-pane" id="paper">

								<div class="product-image v-middle">
									<div class="col-sm-12">
										<img src="/images/img-paper.png" alt="ideal 1" class="v-middle">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>

		<div style="position:relative;">
			<footer-section></footer-section>
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import moment from 'moment'
import { mapState } from 'vuex';
export default {
	watch: {
		product(value) {
			this.loaded = false;
			setTimeout(() => {
				this.loaded = true
			}, 1000);
		},
		'$route.params.id': {
			immediate: true,
			handler(newId) {
				this.fetchProduct(newId);
			}
		}
	},
	computed: {
		...mapState(['user', 'mainProduct', 'subProducts', 'userPCP', 'categories', 'subCategories', 'mainProductsAll', 'products']),
		vw() {
			const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			console.log(vw)
			return vw
		},
		product() {
			if (this.finalProductsSix.length && this.q.printed.length) {
				console.log("we have a product")
				return this.finalProductsSix[0]
			} else {
				console.log("we have no product")
				return null
			}
		},
		finalProducts() {
			let products = [];
			//sided

			if (this.q.printed.length) {
				this.subProducts.forEach(element => {
					if (element.printed) {
						if (element.printed == this.q.printed) {
							products.push(element)
						}
					} else {
						products.push(element)
					}

				});
			} else {
				products = this.subProducts
			}
			return products;
		},
		finalProductsTwo() {
			//productPaperSizeFormat
			let productsPPSF = [];

			if (this.q.productPaperSizeFormat.length) {
				this.finalProducts.forEach(element => {
					if (element.productPaperSizeFormat == this.q.productPaperSizeFormat) {
						productsPPSF.push(element)
					}
				});
				// productsPPSF = this.finalProducts.filter((item) => { item.productPaperSizeFormat == this.q.productPaperSizeFormat })			
			} else {
				productsPPSF = this.finalProducts
			}
			return productsPPSF
		},
		finalProductsThree() {
			try {
				let productsPPTW = []
				if (this.q.productPaperTypeWeight.length) {
					this.finalProductsTwo.forEach(element => {
						if (element.productPaperTypeWeight == this.q.productPaperTypeWeight) {
							productsPPTW.push(element)
						}
					});
					// productsPPTW = this.finalProductsTwo.filter((item) => { item.productPaperTypeWeight == this.q.productPaperTypeWeight })			
				} else {
					productsPPTW = this.finalProductsTwo
				}
				return productsPPTW
			} catch (error) {
				return this.finalProductsTwo
			}
			//productPaperTypeWeight

		},
		finalProductsFour() {
			//foldingOption
			let productsFO = []
			if (this.q.foldingOption.length) {
				this.finalProductsThree.forEach(element => {
					if (element.foldingOption == this.q.foldingOption) {
						productsFO.push(element)
					}
				});
				// productsFO = this.finalProductsThree.filter((item) => { item.foldingOption == this.q.foldingOption })			
			} else {
				productsFO = this.finalProductsThree
			}
			return productsFO;
		},
		finalProductsFive() {

			//foldingOption
			let productsBP = []
			if (this.q.bookletPagination.length) {
				this.finalProductsFour.forEach(element => {
					if (element.bookletPagination == this.q.bookletPagination) {
						productsBP.push(element)
					}
				});
				// productsBP = this.finalProductsFour.filter((item) => { item.bookletPagination == this.q.bookletPagination })			
			} else {
				productsBP = this.finalProductsFour
			}
			return productsBP
		},
		finalProductsSix() {

			//foldingOption
			let productsBT = []
			if (this.q.bookletType.length) {
				this.finalProductsFive.forEach(element => {
					if (element.bookletType == this.q.bookletType) {
						productsBT.push(element)
					}
				});
				// productsBT = this.finalProductsFive.filter((item) => { item.bookletType == this.q.bookletType })			
			} else {
				productsBT = this.finalProductsFive
			}

			return productsBT

			//   this.subProducts.forEach(element => {

			//   });
		},
		sideProducts() {
			let products = []
			this.subProducts.forEach(element => {
				if (element.printed == this.q.printed) products.push(element)
			});
			return products;
		},
		QproductPaperSizeFormat() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.productPaperSizeFormat.length) names.push(element.productPaperSizeFormat)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		QproductPaperTypeWeight() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.productPaperTypeWeight.length) names.push(element.productPaperTypeWeight)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		QMaterials() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.material.length) names.push(element.material)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		QLaminations() {
			let names = [];
			this.finalProducts.forEach(element => {
				if (element.lamination.length) names.push(element.lamination)
			});
			let uniqueChars = [...new Set(names)];
			return uniqueChars;
		},
		final() {
			let error = ''
			if (this.product) {

				if (this.product.product.productPaperSizeFormat.length && this.q.productPaperSizeFormat.length < 1) error = "Please Select a Size"
				if (this.product.product.productPaperTypeWeight.length && this.q.productPaperTypeWeight.length < 1) error = "Please Select a Type Weight"
				if (this.product.product.foldingOption.length && this.q.foldingOption.length < 1) error = "Please Select a Folding Option"
				if (this.product.product.bookletPagination.length && this.q.bookletPagination.length < 1) error = "Please Select a Booklet Pagination"
				if (this.product.product.bookletType.length && this.q.bookletType.length < 1) error = "Please Select a Booklet Type"
			}
			console.log(error)
			return error.length
		},
		bVerify() {
			if (this.orderQuantity == null) {
				return true
			} else {
				return false
			}
		},
		totalOrderPrice() {
			if (this.product && this.orderQuantity > 0) {
				return (this.product.sellingProductPrice / this.product.quantity) * Number(this.orderQuantity)
			} else {
				if (this.selectedPackage == null) {
					return 0
				} else {
					return this.selectedPackage.price
				}
			}
		},
		VAT() {
			if (this.product && this.totalOrderPrice > 0) {
				return ((this.totalOrderPrice * this.product.supplierVAT) / 100)
			} else {
				return 0
			}
		},
		totalOrderUnits() {
			if (this.orderQuantity > 0) {
				return this.orderQuantity
			} else {
				if (this.selectedPackage == null) {
					return 0
				} else {
					return this.selectedPackage.quantity
				}
			}
		},
	},
	components: { NavBar, FooterSection },
	methods: {
		toggleDisplay(id) {
			this.displayedCategories[id] = !this.displayedCategories[id];
		},
		isDisplayed(id) {
			return this.displayedCategories[id] || false;
		},
		upSide(side) {
			console.log("updating side", side)
			this.q.printed = side
		},
		upOrient(side) {
			console.log("updating orient", side)
			this.q.orient = side
		},
		upMaterial(side) {
			console.log("updating material", side)
			this.q.material = side
		},


		goTo(name, params = {}) {
			this.$router.push({ name, params });
		},
		toggleDisplay(id,) {
			let el = document.getElementById(id);
			console.log(id, el.style.display)
			if (el.style.display == 'block') {
				el.style.display = 'none'
			} else {
				el.style.display = 'block'
			}
		},
		handle(event) {
			this.images = []
			this.allFiles = []
			this.allFiles = event.target.files[0]
			console.log(event, this.allFiles)
			const reader = new FileReader();
			reader.readAsDataURL(this.allFiles);
			reader.onload = () => {
				this.images.push(reader.result)
			};

		},
		async startUpload() {
			if (this.allFiles !== null) {
				let name = Date.now() + this.allFiles.name
				const formData = new FormData();
				formData.append('encType', 'multipart/form-data');

				formData.append('artworks', this.allFiles, name)
				//console.log(formData)
				//   let DD = Date.now()
				let dec = await this.$store.dispatch("uploadFilesArtworks", formData)
				return name
			} else {
				return null
			}
		},
		async cartSuccess() {
			this.selectedPackage = null;
			this.orderQuantity = null;
			this.success = "Product Has Been Added to Cart";
			await this.$store.dispatch("getUser");
			location.replace("/checkout")
			// this.$store.state.uploadProgress = 0;
			// this.allFiles = null
		},
		async addToCart() {
			this.error = ''
			//console.log(this.q,"please sir");
			let fileName = (this.allFiles !== null && this.needArtwork) ? await this.startUpload() : null;
			if (this.orderQuantity !== null && this.error.length < 1) {
				let opData = {
					productPaperSizeFormat: this.q.productPaperSizeFormat,
					productPaperTypeWeight: this.q.productPaperTypeWeight,
					foldingOption: this.q.foldingOption,
					bookletPagination: this.q.bookletPagination,
					bookletType: this.q.bookletType,
					material: this.q.material,
					lamination: this.q.lamination,
					printed: this.q.printed,
				}


				let prodDiscountPCP = 0;
				let discountPCPAm = 0;
				if (this.userPCP.length > 0 && moment().isBetween(this.userPCP[0].startAt, this.userPCP[0].endAt)) {
					prodDiscountPCP = this.userPCP[0].discount;
					discountPCPAm = ((this.userPCP[0].discount / 100) * this.orderQuantity.sellingProductPrice).toFixed(3);
				}

				let cartItem = {
					artwork: fileName,
					opData,
					product: this.product._id,
					user: this.user._id,
					isPacakgeUsed: false,
					quantity: this.orderQuantity,
					discountPCP: prodDiscountPCP,
					discountPCPAm: parseFloat(discountPCPAm)
				}
				//console.log(cartItem, "cartItem items")
				//return false;
				let dec = await this.$store.dispatch("addCartItem", cartItem)
				if (dec.status) {
					this.cartSuccess()
				}
			} else {
				this.error = (this.error.length) ? this.error : "Please add any sufficient quantity/amount in item first";
			}
		},
		drop(event) {
			console.log(event)
		},
		async fetchProduct(id) {
			await this.$store.dispatch("getCategories")
			await this.$store.dispatch("getPcps")
			// await this.$store.dispatch("getAllPcps")
			await this.$store.dispatch("getMainProductById", { id: this.$route.params.id });
			this.q.material = this.mainProduct.materials[0]
			if (this.mainProduct.printed.length < 2) {
				if (this.mainProduct.printed[0] == '') {
					this.q.printed = 'Not Supported'
				} else {
					this.q.printed = this.mainProduct.printed[0]
				}
			}
		}

	},
	created() {
		this.fetchProduct(this.$route.params.id);
	},
	beforeRouteUpdate(to, from, next) {
		this.fetchProduct(to.params.id);
		next();
	},
	// async mounted() {
	// 	await this.$store.dispatch("getCategories")
	// 	await this.$store.dispatch("getPcps")
	// 	await this.$store.dispatch("getAllPcps")
	// 	await this.$store.dispatch("getMainProductById", { id: this.$route.params.id });
	// 	this.q.material = this.mainProduct.materials[0]
	// 	if (this.mainProduct.printed.length < 2) {
	// 		if (this.mainProduct.printed[0] == '') {
	// 			this.q.printed = 'Not Supported'
	// 		} else {
	// 			this.q.printed = this.mainProduct.printed[0]
	// 		}
	// 	}

	// },
	data: () => {
		return {
			displayedCategories: {},
			loaded: true,
			orientNew: "Landscape",
			q: {
				printed: "",
				orient: "Landscape",
				material: "",
				lamination: "",
				productPaperSizeFormat: "",
				productPaperTypeWeight: "",
				foldingOption: "",
				bookletPagination: "",
				bookletType: ""
			},
			files: [],
			allFiles: null,
			images: [],
			needArtwork: true,
			productPaperSizeFormat: "",
			productPaperTypeWeight: "",
			foldingOption: "",
			bookletPagination: "",
			bookletType: "",
			error: "",
			success: "",
			orderQuantity: null,
			selectedPackage: null,
			lamination: 'MATT LAMINATION',
			side: "DOUBLE SIDE",
			quantity: [
				20, 50, 100, 150, 200, 250, 300, 500, 1000, 2000, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000
			],
			sizes: [
				"55x85mm",
				"DL (210x99mm)",
				"A7 (74x105mm)",
				"A6 (105x148mm)",
				"A5 (148x210mm)",
				"A4 (210x297mm)",
				"A3 (297x420mm)",
				"A2 (420x524mm)",
				"A1 (594x841mm)",
				"A0 (841x1189mm)"
			],
			bPTypes: [
				"8pp", "44pp",
				"12pp", "48pp",
				"16pp", "52pp",
				"20pp", "56pp",
				"24pp", "60pp",
				"28pp", "64pp",
				"32pp",
				"36pp",
				"40pp"
			],
			bTypes: [
				"A5 Booklet Self Cover",
				"A5 Booklet with Heavy Cover",
				"A5 Booklet with Heavy Cover with Matt Lamination",
				"A5 Booklet with Heavy Cover with Gloss Lamination",
				"A4 Booklet Self Cover",
				"A4 Booklet with Heavy Cover",
				"A4 Booklet with Heavy Cover with Matt Lamination",
				"A4 Booklet with Heavy Cover with Gloss Lamination"
			],
			fOptions: [
				"A6 fold A7 (half fold)",
				"A5 fold A6 (half fold)",
				"198x210mm fold 99x210mm (half fold)",
				"A4 fold A5 (half fold) ",
				"6pp DL – (A4 roll fold DL) ",
				"6pp DL – (A4 Z fold DL) ",
				"210x396mm fold 210x198mm (half fold)",
				"210x396mm fold 210x99mm (Z fold)",
				"210x396mm fold 210x99mm (roll fold)",
				"A3 fold A4 (half fold) ",
				"A3 – A5 (cross fold)",
				"297x140mm (Z fold)",
				"297x140mm (roll fold)",
				"297x105mm (Z fold)",
				"297x105mm (roll fold)",
				"6pp A5 (roll fold)",
				"6pp A5 (Z fold)",
				"6pp A4 (roll fold)",
				"6pp A4 (Z fold)",
				"A2 fold A3 (half fold) ",
				"A2 to 210x99mm (map fold)"
			],
			types: [
				"130gsm Silk",
				"130gsm Gloss",
				"120gsm Bond / Offset",
				"170gsm Silk ",
				"170gsm Gloss",
				"250gsm Gloss",
				"300gsm Bond",
				"300gsm Silk",
				"350gsm Silk",
				"400gsm Silk",
				"400gsm Silk with Matt lamination",
				"400gsm Silk with Gloss Lamination"
			],
		}
	}

}
</script>

<style>
.v-input {
	position: absolute;
	z-index: 9999;
	height: 100%;
	border: 2px solid red;
	width: 100%;
	opacity: 0;
}

.product-info-w {
	position: relative;
}

.product-card-wrapper {
	margin-top: 20%;
}

.product-shortdescript {
	display: block;
}

.product-wraper {
	padding: 0;
}

.sidebar-title {
	margin-bottom: 0 !important;
}

.c-navbar__sticky .c-navbar__item.second-header a::before {
	opacity: 0;
}

.product-info-w #cate_list li a {
	font-size: 12px;
	padding: 0px 10px;
}
</style>