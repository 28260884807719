<template>
	<div v-if="mainSingleProduct">
		<nav-bar></nav-bar>
		<main class="main" id="product-detail">
			<section class="product-info-w">
				<div class="container">
					<div class="row">
						<div class="tab-content clearfix">
							<div role="tabpanel" class="tab-pane active" id="features">
								<div class="product-image">
									<div v-if="loaded">
										<div class="col-sm-12 col-xs-12" v-if="mainSingleProduct.quoteSupplierImage">
											<img :src="$store.state.URL+'/uploads/supplier-quotes/'+mainSingleProduct.quoteSupplierImage" alt="ideal 1">
										</div>
										<div class="col-sm-12 col-xs-12" v-else>
											<img :src="$store.state.URL+'/uploads/supplier-quotes/'+mainSingleProduct.quoteSupplierImage" alt="ideal 1">
										</div>
										<div class="col-sm-12">
											<a @click="goTo('HowToOrderOnline')"> <b>How to Order Online ? Click here to view</b> </a>
										</div>
									</div>
								</div>
								<div class="product-shortdescript v-middle" v-if="mainSingleProduct">
									<div class="col-sm-12 col-xs-12">
										<div class="" v-if="needArtwork">
											<h3 style="margin-bottom:15px;">VestiGO Quote Order</h3>
											<div class="col-12" style="margin-bottom:15px;">
												<div class="rating-box">  
													<i class="fa fa-star"></i>
													<i class="fa fa-star"></i>
													<i class="fa fa-star-half-o"></i>
													<i class="fa fa-star-o"></i>
													<i class="fa fa-star-o"></i>
													<span>(23 Customer Reviews)</span>
												</div>
											</div>
											<p>
												{{ mainSingleProduct.suppliersDesription }}
											</p>

											<div v-if="mainSingleProduct !== null">
												<label for="">Order Quantity </label><br>
												<select name="" id="" class="form-control" v-model="orderQuantity">
													<option :value="mainSingleProduct">
														{{ mainSingleProduct.quantity.quantity }} Units for &euro; {{ Number(mainSingleProduct.quantity.sellingProductPrice).toFixed(2) }}
													</option>
												</select>
											</div>

											<div v-if="mainSingleProduct !== null">
												<label for="">Delivery Turnaround </label><br>
												<p> <b> {{ mainSingleProduct.deliveryTurnaround }} Days </b> </p>
											</div>

											<!-- <div v-if="mainProduct.artworkInst" style="margin-top:10px;"> <b>Note:</b> {{ mainProduct.artworkInst }}</div> -->

											<div class="" style="padding:10px;border:2px solid lightgray;" v-if="needArtwork && mainSingleProduct.productProcess != 'Non Print'">
												<p> <b>Upload Your Artwork</b> </p>
												<p>If you are experiencing issues on uploading your artwork or need to discuss issues around your artwork file,  you can email us at artwork@vbs.com and out team will help support on ensuring your artwork is correct.</p>
												<p>Please check our <a href="/artwork-guide" target="_blank"  style="font-weight:600; text-decoration: underline;">Artwork Guide</a> </p>
												<div style="border:1px dotted lightgray;padding:10px;position:relative;">
													<input type="file" name="" id="" class="v-input" @drop="drop"  @change="handle">
													<img src="/images/download.png" alt="" class="" srcset="" style="width:110px;display: block; margin-left:auto; margin-right:auto;">
													<p>Click here to choose the files or drag them here</p>
												</div>
												<div > 
												</div>
												<div v-if="$store.state.uploadProgress">
													<b>Uploading:</b> <span> {{ $store.state.uploadProgress }}% </span>
												</div>
												<div v-if="allFiles">
														{{ allFiles.name }}
												</div>
                                        	</div>
											<div v-else>
												<h4 v-if="mainSingleProduct.productProcess != 'Non Print'">
													Email our Design Team with your order number after completing the order to
													design@vestigoprint.com if you require design support. Our team will respond with design
													price that begins @ €50.00 for basic artwork print support, and we will let you design pricing
													for more complicated print artwork.
												</h4>
											</div>

										</div>
										<div v-else>
											<b> <i> Upload your Design Content<br>
											Include all relevant design
											content for example logos, images, content text and contact details	<br>
											The VestiGO Design Team will respond with a
											design price within 24 hours<br>
											Our Design Prices start
											from €50.00 for basic design upgrades on print products like business cards, flyers
											etc.,' 'For larger design requirements, we will offer a competitive design price based on
											review of your design brief
											</i> </b>
										</div>
										<div v-if="mainSingleProduct">
											<div v-if="orderQuantity">											
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> SUB TOTAL: 
													<span v-if="orderQuantity">&euro; {{ $LFT(orderQuantity.quantity.sellingProductPrice) }}</span>											
												</h3>
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> +VAT({{ orderQuantity.quantity.supplierVATNumber }}%): 
													<span v-if="orderQuantity">&euro; {{  $LFT((orderQuantity.quantity.sellingProductPrice * orderQuantity.quantity.supplierVATNumber) / 100) }}</span>
												</h3>
												<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL: 
													<span v-if="orderQuantity">&euro; {{ $LFT( ((orderQuantity.quantity.sellingProductPrice * orderQuantity.quantity.supplierVATNumber) / 100) + orderQuantity.quantity.sellingProductPrice  ) }}</span>
												</h3>	 
												<h4 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL UNITS: 
													<span v-if="orderQuantity">{{ orderQuantity.quantity.quantity }}</span>
												</h4>
											</div>
											
										</div>

										<button class="btn btn-success" v-if="user" :disabled="bVerify" @click="addToCart" style="border-radius:0px;width:100%;margin-top:5px;">ADD TO BASKET</button> 
										<div v-else>
    										<button class="btn btn-primary form-control" @click="$store.state.showModal = true">Login/Regsiter</button>
										</div>

										<p class="text-danger" v-if="error.length">{{ error }}</p>
										<p class="text-success" v-if="success.length">{{ success }}</p>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import moment from 'moment'
import {mapState} from 'vuex';
export default {

	computed:{
		...mapState(['user','mainSingleProduct', 'userPCP']),
		

		bVerify(){
			if (this.orderQuantity == null) {
				return true
			}else{
                return false
			}
		},
		totalOrderPrice(){
			if (this.orderQuantity > 0) {
				return (this.product.sellingProductPrice / this.product.quantity) * Number(this.orderQuantity)
			}else{
				if (this.selectedPackage == null) {
					return 0
				}else{
					return this.selectedPackage.price
				}
			}
		},
		VAT(){
			if (this.product && this.totalOrderPrice > 0) {
				return ((this.totalOrderPrice*this.product.supplierVAT) / 100 )
			}else{
				return 0
			}
		},
	},
    components:{NavBar, FooterSection},
	methods:{
		
		goTo(name){
			this.$router.push({name})
		},
		handle(event){
            this.images = []
            this.allFiles = []
            this.allFiles = event.target.files[0]
			console.log(event, this.allFiles)
			const reader = new FileReader();
                reader.readAsDataURL(this.allFiles);
                reader.onload = () => {
                    this.images.push(reader.result)
                };
        
        },
		async startUpload(){
			if(this.allFiles !== null){
				let name = Date.now()+this.allFiles.name
				const formData = new FormData();
            formData.append('encType', 'multipart/form-data');

		  formData.append('artworks', this.allFiles, name)
          let dec = await this.$store.dispatch("uploadFilesArtworks", formData )
		  return name
			}else{
				return null
			}

        },
		async cartSuccess(){
			this.selectedPackage = null;
			this.orderQuantity = null;
			this.success = "Product Has Been Added to Cart";
			await this.$store.dispatch("getUser");
			location.replace("/checkout")
		},
		async addToCart(){
			this.error = ''
			//console.log(this.q,"please sir");
			let fileName = (this.allFiles !== null && this.needArtwork) ? await this.startUpload() : null;
			if (this.orderQuantity !== null && this.error.length < 1) {

			let prodDiscountPCP = 1;
			let discountPCPAm = 0;
			// if( this.userPCP.length > 0 && moment().isBetween(this.userPCP[0].startAt, this.userPCP[0].endAt)) {
			// 	prodDiscountPCP = this.userPCP[0].discount;
			// 	discountPCPAm =  ((this.userPCP[0].discount/100)*this.orderQuantity.sellingProductPrice).toFixed(3);
			// }
		
			 	let cartItem = {
					artwork:fileName,
					user: this.user._id,
					quote_id:this.$route.params.id,
					isPacakgeUsed: false,
					quantity: this.orderQuantity.quantity,
					discountPCP: prodDiscountPCP,
					discountPCPAm: parseFloat(discountPCPAm)
				}
				console.log(cartItem, "cartItem items")
				//return false;
				let dec = await this.$store.dispatch("addCartItem", cartItem)
				if (dec.status) {
					this.cartSuccess()
				}
			}else{
				this.error = (this.error.length) ? this.error : "Please add any sufficient quantity/amount in item first";
			}
		},
		drop(event){
			console.log(event)
		}
	},
	async mounted(){
		this.$store.dispatch("getPcps"),
		this.$store.dispatch("getAllPcps"),
        await this.$store.dispatch("getMainSingleProductById",{id: this.$route.params.id});
		this.orderQuantity = this.mainSingleProduct;
		
	},
    data:() => {
        return {
			loaded:true,
			files : [],
            allFiles : null,
            images : [],
			needArtwork:true,
			
			error:"",
			success:"",
			orderQuantity:null,
			selectedPackage:null,  
        }
    }

}
</script>

<style>
.v-input{
	position: absolute;
    z-index: 9999;
    height: 100%;
    border: 2px solid red;
    width: 100%;
    opacity: 0;
}
</style>