<template>

  <div>
    <nav-bar></nav-bar>
    <main class="main">

      <div class="wrapper">
        <h1 class="red-heading">quote Request Form</h1>

        <div class="quote-wrapper">

          <!-- left section  -->
          <div class="quote-left">
            <div class="quote-heading-left">
              <p>If a print quote, please submit
                as much information as
                possible on your requirement including where applicable:
              </p>
              <ul class="check-list">
                <li>
                  <div class="check">
                    <p>Description of finished print product.</p>
                  </div>
                </li>
                <li>
                  <div class="check">
                    <p>Preferred finished paper type and weights.</p>
                  </div>
                </li>
                <li>
                  <div class="check">
                    <p>Folding requirements if needed.</p>
                  </div>
                </li>
                <li>
                  <div class="check">
                    <p>Design support if needed.</p>
                  </div>
                </li>
                <li>
                  <div class="check">
                    <p>Quantity of Product Needed.</p>
                  </div>
                </li>
                <li>
                  <div class="check">
                    <p>Upload Existing Design.</p>
                  </div>
                </li>
              </ul>
            </div>

            <div class="quote-msgbox">
              <p>If  you  require  product  or  services  not  listed  within  our  online  offerings,  please  outline  your  requirements  within  our  'Message  &  Quote'  form  and  any  supporting  images  or  files  within  our  'Upload  File'  box.  Our  team  will  respond  with  a  competitive  quote  within  24 - 72  hours </p>
            </div>


          </div>

          <!-- right section  -->
          <div class="quote-right">
            <div class="form-section">
              <div class="message">

              <label for="">Message & Quote Details<span class="required">*</span></label>
              <div class="required-field">
                <textarea cols="30" rows="10" v-model="requestMessage"
                  placeholder="Could you please quote for an A4 20pp Brochure with your silk paper finish, all the details from your online version of this product including the heavy cover but we only require a quantity of 100. I have uploaded our artwork of the brochure that may help you further."></textarea>
                  <p class="required" v-if="requestMessageError.length"> {{ requestMessageError }} </p>
              </div>

              </div>
              <div class="upload-media">
                <label for="">Upload File(s)
                  <p></p>
                </label>
                <div class="required-field">
                  <label for="" class="file-upload">
                    <input type="file" @change="handle">
                  </label>
                    <p class="required" v-if="quoteImageError.length"> {{ quoteImageError }} </p>
                </div>
              </div>
              <div class="file-text-wrapper"  v-if="allFiles">
                <label for=""><p></p></label>
                  <div class="file-name-text">
                    <p>{{ allFiles.name }}</p>
                  </div>
              </div>
            </div>
            

            

            <div class="form-section">

              <div class="business-details">
                <div class="customer-date">
                  <label for="">Date</label>
                  <p>{{ currentDate }}</p>
                  <!-- <input type="date" v-model="requestDate" name="" id=""> -->
                </div>
                <div class="business-name">
                  <label for="">Business name</label>
                  <p>{{ user.accountTitle }}</p>
                </div>

                <div class="customer-acc">
                  <label for="">Customer Account No.</label>
                  <p>{{ user.userCode }}</p>
                </div>

              </div>

              

              
              <div class="quote-heading-right">
                <h2>Do you need design support?</h2>
                <p>We can help upgrade or create print ready PDF files from your existing design files OR create a bespoke design that suits your print requirements. Please send us your Design brief, including any existing content/image files that can be uploaded using our Upload File(s) box above. Our creative and friendly design team will respond to your requirements within 24-72 hours, and we offer competitive design prices.</p>
              </div>
              

              <div class="buttons">
                <p></p>
                <button class="submit-btn" @click="addQuote" :disabled="isLoading">
                  <span v-if="isLoading">Loading...</span>
                  <span v-else>Submit</span></button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </main><!--Main index : End-->
    <div style="position:relative;">
      <footer-section></footer-section>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  components: { NavBar, FooterSection },
  computed: {
    ...mapState(['user']),
  },

  methods: {
    async addQuote() {
      this.isLoading = true;
      this.requestMessageError = '';
      this.quoteImageError = '';
      let quoteImage = (this.allFiles !== null) ? await this.startUpload() : null;

      if (!this.requestMessage) {
        this.requestMessageError = 'MESSAGE & QUOTE DETAILS field is required';
        this.isLoading = false;
        return;
      }

      // if (!quoteImage) {
      //   this.quoteImageError = 'Artwork or Design Files is required';
      //   this.isLoading = false;
      //   return;
      // }  

      let userData = {
        user: this.user,
        userCode: this.user.userCode,
        desription: this.requestMessage,
        requestDate : this.currentDate,
        quoteImage: quoteImage,
      };

      let dec = await this.$store.dispatch("createQuote", userData);

      if (dec.status) {
        this.desription = '';
        this.$router.push({path:"/"})
      }
      else {
        this.error = dec.message;
      }
    },

    handle(event) {
      this.images = []
      this.allFiles = []
      this.allFiles = event.target.files[0]
      console.log(event, this.allFiles)
      const reader = new FileReader();
      reader.readAsDataURL(this.allFiles);
      reader.onload = () => {
        this.images.push(reader.result)
      };
    },
    async startUpload() {
      if (this.allFiles !== null) {
        let name = Date.now() + this.allFiles.name
        const formData = new FormData();
        formData.append('encType', 'multipart/form-data');
        formData.append('artworks', this.allFiles, name)
        let dec = await this.$store.dispatch("uploadFilesArtworks", formData)
        return name
      } else {
        return null
      }

    },
  },

  data() {
    return {
      requestMessage: '',
      requestMessageError:'',
      quoteImageError:'',
      error: '',
      allFiles : null,
      currentDate: '',
      isLoading: false,
    };
  },
  mounted() {
    this.currentDate = moment().format('dddd DD MMMM');
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

li {
  list-style: none;
}

.wrapper {
  width: 100%;
}

.wrapper .red-heading {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  color: #c32025;
  display: flex;
  margin: 50px 0 25px;
  justify-content: center;
}

.quote-wrapper {
  max-width: 1352px;
  width: 100%;
  margin: auto;
  border: 0.5px solid #22222240;
  padding: 50px 29px 50px 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  gap: 207px;
  margin-bottom: 100px;
}

/* quote-left  */
.quote-left {
  max-width: 444px;
  width: 100%;
}

.quote-left p {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
  color: #242656;
  line-height: 22px;
}

.quote-heading-left {
  padding-left: 24px;
}

.check-list {
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-top: 25px;
}

.check p {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding-left: 27px;
}

.check::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background-image: url('~@/assets/check.png');
}

.quote-msgbox {
  max-width: 428px;
  width: 100%;
  margin-top: 31px;
  border: 0.5px solid #22222222;
  border-radius: 4px;
  padding: 16px;
}

.quote-msgbox p {
  font-size: 22px;
  font-weight: 300;
  color: #242656;
  font-weight:600;
  line-height: 1.5;
}

/* quote-right */

.quote-right {
  max-width: 632px;
  width: 100%;
}

.quote-heading-right {
  max-width: 557px;
  width: 100%;
}

.quote-right .quote-heading-right h2 {
  font-size: 24px;
  font-weight: 500;
  color: #242656;
}

.quote-right .quote-heading-right p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  line-height: 18px;
}

.form-section {
  width: 100%;
  margin-top: 18px;
  margin-top: 43px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-start;
  /* Replace 'start' with 'flex-start' */
  align-items: flex-start;
  /* Replace 'start' with 'flex-start' */
}

.business-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.business-name,
.customer-acc,
.customer-date {
  display: flex;
  justify-content: flex-start;
  /* Replace 'start' with 'flex-start' */
  gap: 43px;
}

.business-details label {
  max-width: 191px;
  width: 100%;
}

.business-details p {
  max-width: 401px;
  width: 100%;
}

.form-section label {
  font-size: 16px;
  font-weight: 500;
  color: #242656;
  text-transform: uppercase;
}

.form-section p {
  font-size: 16px;
  font-weight: 400;
  color: #444444;
}

.date,
.message {
  width: 100%;
  display: flex;
  gap: 43px;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.business-name,
.customer-acc p {
  align-self: left;
}

.form-section input {
  max-width: 401px;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
  border: 1px solid #d8dde3;
  border-radius: 4px;
}

.form-section input:focus {
  outline: none;
}

.form-section input[type="date"] {
  max-width: 401px;
  width: 100%;
  padding: 14px;
  position: relative;
  text-transform: uppercase;
  color: #8E8E8E;
}

.form-section input[type="date"]::-webkit-calendar-picker-indicator {
  visibility: visible;
  width: 100%;
  height: 40px;
  opacity: 0;
  position: absolute;
}

.form-section input[type="date"]::after {
  content: "";
  position: absolute;
  background-image: url('~@/assets/calender-icon.png');
  width: 19px;
  height: 19px;
  background-repeat: no-repeat;
  right: 11px;
  top: 11px;
}

.form-section input[type="text"] {
  padding: 12px 16px;
  color: #8E8E8E;
}

.message textarea {
  max-width: 401px;
  width: 100%;
  height: 159px;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #8E8E8E;
  border: 1px solid #d8dde3;
  border-radius: 4px;
}

.message textarea:focus {
  outline: none;
}

.message textarea::placeholder {
  color: #8E8E8E;
}

.upload-wrapper{
  width: 100%;
    display: flex;
    justify-content: space-between;
}

.upload-wrapper .file-name{
  max-width: 402px;
  width: 100%;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.upload-media input[type='file']::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/upload-media.png');
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 1;
  left: 23.5px;
  top: 15.5px;
}

.buttons .submit-btn {
  max-width: 401px;
  width: 100%;
  padding: 8px 0;
  background-color: #c32025;
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  line-height: 24px;
}
.submit-btn span{
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.message,
.upload-media,
.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}



.upload-media .file-upload{
  max-width: 402px;
  width: 100%;
  height: 120px;
  color: #8e8e8e;
  font-size: 9px;
  font-weight: 400;
  border: 1px dashed #242656;
  border-radius: 4px;
  position: relative;
  z-index: 2;
}

.upload-media input[type="file"] {
  max-width: 402px;
  width: 100%;
  padding: 17.5px 23.5px;
  color: #8e8e8e;
  font-size: 9px;
  font-weight: 400;
  border: 1px dashed #242656;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  visibility: visible;
  opacity: 0;
}


.upload-media .file-upload::before{
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/upload-media.png');
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 1;
  left: 23.5px;
  top: 17.5px;
}

.upload-media .file-upload::after{
  content: 'Upload OR Drag Existing Artwork or Design Files or Sample Folding Images / files.';
  position: absolute;
  text-transform: lowercase;
  font-size: 15px;
  top: 17.5px;
  left: 50px;
}

.file-text-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.file-name-text {
  max-width: 402px;
  width: 100%;
  text-transform: lowercase;
}

.form-section .required{
  color: red;
  font-size: 10px;
  padding-left: 10px;
}

.required-field{
  max-width: 401px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

</style>